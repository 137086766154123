import React, { Component } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import './Thumb.css'

class Thumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
        thumb: undefined
      }

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ thumb: reader.result});
    }
    reader.readAsDataURL(props.file);
  }
  
  render() {
    const { thumb } = this.state;
    return (
      <div className="thumbnail-container"
        style={{height: this.props.size}}
      >
      {thumb != undefined ?
        <React.Fragment>
          { thumb.includes("image") ?
            <img
              src={thumb} style={{
                maxWidth: this.props.size,
                maxHeight: this.props.size
              }}
              className="img-thumbnail mt-2 uploaded-icon"
            /> : <PictureAsPdfIcon 
            style={{
                width: "100px",
                height: "100px",
                color: "#c9c9c9"
              }}
            />
          }
        </React.Fragment> : <span></span>
      }
      </div>
    )
  }
}

export default Thumb
