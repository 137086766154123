import { SET_FILTER } from "../actions/actionTypes";
import { USER_TYPE_FILTERS } from "../constants";

const initialState = USER_TYPE_FILTERS.ALL;

const userTypeFilter = (state = initialState, action) => {
  switch(action.type) {
    case SET_FILTER: {
      return action.payload.filter;
    }
    default:
      return state;
  }
}

export default userTypeFilter;