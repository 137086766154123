/* eslint-disable no-useless-escape */
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {setDocument} from '../../../redux/actions/actions';

export default withFormik({
  displayName: 'WizardForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    uploadedFileName: [],
    uploadedGaranzia: [],
    uploadedDifetto: [],
    uploadedDdt: [],
    noleggioAcquisto: '',
    noleggio_oppure_Acquisto: '',
    rivenditore: '',
    societaPrivato: '',
    dati_utilizzatore: props.dati_utilizzatore,
    datiIdentificativo: '',
    tipo_identificativo: '',
    input_codice_univoco: '',
    input_pec: '',
    descrizioneFrequenzaProblema: '',
    articoloInGaranzia: '',
    aggiungiFotoDifetto: '',
    modalitaTrasporto: '',
    numero_contratto: '',
    ragione_sociale: '',
    partita_iva: '',
    email: '',
    telefono: '',
    nome_e_cognome: '',
    codice_fiscale: '',
    codice_articolo: '',
    nome_articolo: '',
    descrizione_articolo: '',
    matricola_o_lotto_articolo: '',
    descrizione_guasto: '',
    descrizione_casistica: '',
    descrizione_tentativi: '',
    descrizione_difetto: '',
    numero_pezzi: '',
    indirizzo_ritiro: '',
    citta_ritiro: '',
    provincia_ritiro: '',
    cap_ritiro: '',
    telefono_ritiro: '',
    ragione_sociale_rivenditore: '',
    citta_rivenditore: '',
    piva_rivenditore: '',
    rivenditore_presente: '',
    trasporto_a_cura_di: '',
    mailAssistenza: props.mailAssistenza,
    tipologia_richiesta: props.tipologia,
    tipologiaId: props.tipologiaId,
    societaNoleggio: '',
    societa_noleggio: '',
    add_article: '',
    // articles: props.articles,
    userDetails: props.userDetails
    // ragione_sociale_utente: '',
    // partita_iva_utente: '',
    // telefono_utente: '',
    // email_utente: ''
  }),
  validationSchema: () =>
    Yup.object().shape({
      noleggio_oppure_Acquisto: Yup.string()
        .when('dati_utilizzatore', {
          is: 'distributore',
          then: Yup.string()
            .when('tipologiaId', {
              is: 'resart',
              then: Yup.string()
                .required('Scelta obbligatoria')
            })
            .when('tipologiaId', {
              is: 'segpro',
              then: Yup.string()
                .required('Scelta obbligatoria')
            })
        }),
        societa_noleggio: Yup.string()
        .when('noleggio_oppure_Acquisto', {
          is: 'noleggio',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      numero_contratto: Yup.string()
        .when('noleggio_oppure_Acquisto', {
          is: 'noleggio',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      // ragione_sociale: Yup.string()
      //   .when('noleggio_oppure_Acquisto', {
      //     is: 'noleggio',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // partita_iva: Yup.string()
      //   .matches(/^[0-9]{11}$/, 'Deve avere 11 cifre')
      //   .when('noleggio_oppure_Acquisto', {
      //     is: 'noleggio',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // telefono: Yup.string()
      //   .when('noleggio_oppure_Acquisto', {
      //     is: 'noleggio',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // email: Yup.string()
      //   .email('Email non valida')
      //   .when('noleggio_oppure_Acquisto', {
      //     is: 'noleggio',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // societa_noleggio: Yup.string()
      // .when('noleggio_oppure_Acquisto', {
      //   is: 'noleggio',
      //   then: Yup.string()
      //     .required('Campo obbligatorio')
      // }),
      // telefono: Yup.string()
      //   .required('Campo obbligatorio'),
      // email: Yup.string()
      //   .email('Email non valida')
      //   .required('Campo obbligatorio'),
      rivenditore_presente: Yup.string()
        .when('dati_utilizzatore', {
          is: 'partita_iva',
          then: Yup.string()
            .when('noleggio_oppure_Acquisto', {
              is: 'acquisto',
              then: Yup.string()
                .required('Scelta obbligatoria')
            }),
          })
          .when('dati_utilizzatore', {
            is: 'privato',
            then: Yup.string()
              .required('Scelta obbligatoria')
            }),
      ragione_sociale_rivenditore: Yup.string()
        .when('rivenditore_presente', {
          is: 'rivenditoreSi',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      citta_rivenditore: Yup.string()
        .when('rivenditore_presente', {
          is: 'rivenditoreSi',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      piva_rivenditore: Yup.string()
        .matches(/^[0-9]{11}$/, 'Deve avere 11 cifre')
        .when('rivenditore_presente', {
          is: 'rivenditoreSi',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),

      // tipo_identificativo: Yup.string()
      //   .when('dati_utilizzatore', {
      //     is: 'partita_iva',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }), 
      // input_pec: Yup.string()
      //   .when('dati_utilizzatore', {
      //     is: 'partita_iva',
      //     then: Yup.string()
      //       .when('tipo_identificativo', {
      //         is: 'pec',
      //         then: Yup.string()
      //           .email('Email non valida')
      //           .required('Campo obbligatorio')
      //       })
      //   }),
      // input_codice_univoco: Yup.string()
      //   .when('dati_utilizzatore', {
      //     is: 'partita_iva',
      //     then: Yup.string()
      //       .when('tipo_identificativo', {
      //         is: 'codice_univoco',
      //         then: Yup.string()
      //           .required('Campo obbligatorio')
      //     })
      //   }),

      add_article: Yup.string()
        .when('tipologiaId', {
          is: 'segpro',
          then: Yup.string()
          .required('Campo obbligatorio'),
        }),
      // descrizione_guasto: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'segpro',
      //     then: Yup.string()
      //      .required('Campo obbligatorio'),
      //   }),
      // quando_si_verifica_il_problema: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'segpro',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),

      // descrizione_casistica: Yup.string()
      //   .when('quando_si_verifica_il_problema', {
      //     is: 'occasionalmente',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // matricola_o_lotto_articolo: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),
      // codice_articolo: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),
      // articolo_in_Garanzia: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),

      // descrizione_difetto: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),
      // numero_pezzi: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //       .required('Campo obbligatorio')
      //   }),
      // allega_foto: Yup.string()
      //   .when('tipologiaId', {
      //     is: 'resart',
      //     then: Yup.string()
      //     .required('Campo obbligatorio'),
      //   }),
        
      trasporto_a_cura_di: Yup.string()
        .when('tipologiaId', {
          is: 'resart',
          then: Yup.string()
          .required('Campo obbligatorio'),
      }),
      indirizzo_ritiro: Yup.string()
        .when('trasporto_a_cura_di', {
          is: 'Muster',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      citta_ritiro: Yup.string()
        .when('trasporto_a_cura_di', {
          is: 'Muster',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      provincia_ritiro: Yup.string()
        .when('trasporto_a_cura_di', {
          is: 'Muster',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      cap_ritiro: Yup.string()
        .when('trasporto_a_cura_di', {
          is: 'Muster',
          then: Yup.string()
            .required('Campo obbligatorio')
        }),
      telefono_ritiro: Yup.string()
        .when('trasporto_a_cura_di', {
          is: 'Muster',
          then: Yup.string()
            .required('Campo obbligatorio')
        })
    }),
  handleSubmit: (values, formikBag) => {
    // console.log(formikBag.props.articles)
    const payload = {
      values,
      articles: formikBag.props.articles
    }
    fetch(process.env.REACT_APP_BACKEND_URL+'/data/incoming', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        param: payload
      })
    }).then((response) => {
      return response.json();
    }).then(res => {
      // console.log(res.payload.document);
      formikBag.props.dispatch(setDocument(res.payload.document))
    });
  },
});