import React, {useEffect} from 'react';
import Fade from '@material-ui/core/Fade';

const Minuterie = (props) => {
  useEffect(() => {
    props.close("Settori", "settori");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);

  return(
    <Fade in={props.checked}>
      <div>
        <h2>Ecco come puoi contattarci:</h2>
        <p>Scrivici un'e-mail:</p>
        <ul>
          <li>
            <strong> alessandro.colombo@muster-dikson.com</strong>
          </li>
        </ul>
      </div>
    </Fade>
  )
}

export default Minuterie;