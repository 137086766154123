const navigationReducer = (state, action) => {
  switch (action.type) {
    case 'NAVIGA_SETTORE':
      return {
          ...state,
          current: action.payload.current,
          currentId: action.payload.currentId,
          close: action.payload.close,
          closeId: action.payload.closeId,
      }
    case 'CHIUDI_MODULO':
      return {
          ...state,
          close: action.payload.close,
          closeId: action.payload.closeId,
      }
    case 'TEMP_NAV':
      return {
          ...state,
          temp: action.payload.temp,
          tempId: action.payload.tempId,
      }
    default:
      return state
  }
}
export { navigationReducer as default };
