import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import SettoriContext from '../../context/settori-context';
import { Settore } from './Settore';

const SettoriList = (props) => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      margin: "10px 0",
      paddingLeft: theme.spacing(4),
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    props.close("Home", "Home");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);

  return(
    <Fade in={props.checked}>
      <Grid container direction="column" alignItems="center" justify="center">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Seleziona il tuo settore
            </ListSubheader>
          }
          className={classes.root}
        >
          <SettoriContext.Consumer>
            {value => {
              return value.dati.settori.map((settore) => {
                return(
                  <Settore 
                    key={settore.id} 
                    id={settore.id}
                    classes={classes} 
                    tit={settore.nome}
                    cat={settore.categorie}
                    description={settore.description}  
                  />
                )
              })
            }}
          </SettoriContext.Consumer>
        </List>
      </Grid>
    </Fade>
  )
}

export { SettoriList as default }
