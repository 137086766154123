import {
  SET_USERTYPE,
  SET_TIPOIDENTIFICATIVO,
  SET_USERDETAILS, 
  // SET_PIVADETAILS
} from '../actions/actionTypes';

const initialState = {
  type: '',
  ragione_sociale: '',
  nome_e_cognome: '',
  partita_iva: '',
  codice_fiscale: '',
  telefono: '',
  email: '',
  tipo_identificativo: '',
  input_codice_univoco: '',
  input_pec: '',
};

export default function (state = initialState, action) {
  switch(action.type) {
    case SET_USERTYPE: {
      const {userType} = action.payload;
      return {
        ...state,
        type: userType
      }
    }
    case SET_TIPOIDENTIFICATIVO: {
      const {tipoIdentificativo} = action.payload;
      return {
        ...state,
        tipo_identificativo: tipoIdentificativo
      }
    }
    case SET_USERDETAILS: {
      const userDetails = action.payload;
      let ragione_sociale, nome_e_cognome, partita_iva, codice_fiscale, input_codice_univoco, input_pec = '';
      ragione_sociale = userDetails.ragione_sociale ? userDetails.ragione_sociale : '';
      nome_e_cognome = userDetails.nome_e_cognome ? userDetails.nome_e_cognome : '';
      partita_iva = userDetails.partita_iva ? userDetails.partita_iva : '';
      codice_fiscale = userDetails.codice_fiscale ? userDetails.codice_fiscale : '';
      codice_fiscale = userDetails.codice_fiscale ? userDetails.codice_fiscale : '';
      input_codice_univoco = userDetails.input_codice_univoco ? userDetails.input_codice_univoco : '';
      input_pec = userDetails.input_pec ? userDetails.input_pec : '';
      return {
        ...state,
        ragione_sociale,
        nome_e_cognome,
        partita_iva,
        codice_fiscale,
        telefono: userDetails.telefono,
        email: userDetails.email,
        input_codice_univoco,
        input_pec
      }
    }
    // case SET_PIVADETAILS: {
    //   const{pivaDetails} = action.payload;
    //   return {
    //     ...state,
    //     ragione_sociale: pivaDetails.ragione_sociale,
    //     partita_iva: pivaDetails.partita_iva,
    //     telefono: pivaDetails.telefono,
    //     email: pivaDetails.email,
    //   }
    // }
    default:
      return state;
  }
}