import React, { useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import NavigationContext from '../../context/navigation-context';

const SelezioneForm = (props) => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    props.close("Settori", "settori");
    // return function closeUpdate() {
    //   props.close(null, null);
    // }
  }, []);

  useEffect(() => {
    props.temp(props.closeDetails.current, props.closeDetails.currentId);
    // return function closeUpdate() {
    //   props.temp(props.closeDetails.current, props.closeDetails.currentId);
    // }
  }, []);
  useEffect(() => {
    switch(props.closeDetails.currentId) {
      case 'pararr':
        return props.mail("debora.lovo@muster-dikson.com");
      case 'parele':
        return props.mail("assistenza.tecnica@muster-dikson.com");
      case 'estarr':
        return props.mail("debora.lovo@muster-dikson.com");
      case 'estatt':
        return props.mail("debora.lovo@muster-dikson.com");
      case 'estapp':
        return props.mail("assistenza.tecnica@muster-dikson.com");
      case 'estele':
        return props.mail("assistenza.tecnica@muster-dikson.com");
      default:
        throw new Error('Unknown component to load');
    }
    // return function closeUpdate() {
    // }
  }, []);

  return (
    <Fade in={props.checked}>
      <NavigationContext.Consumer>
        {value => {
          const click = (current, currentId) => value.handleNavigation(
            current, currentId, props.closeDetails.close, props.closeDetails.closeId
          );
          return(
            <React.Fragment>
              <List
                component="div" disablePadding
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Che cosa vuoi fare?
                  </ListSubheader>
                }
              >
                <ListItem button className={classes.nested} onClick={
                  ()=>click("Segnalazione Problema", "segpro")
                }>
                  <ListItemText primary="Segnalazione Problema"/>
                </ListItem>
                <ListItem button className={classes.nested} onClick={
                  ()=>click("Richiesta reso articolo per assistenza", "resart")
                }>
                  <ListItemText primary="Richiesta reso articolo per assistenza"/>
                </ListItem>
              </List>
            </React.Fragment>
          )
        }}
      </NavigationContext.Consumer>
    </Fade>
  )
}
export { SelezioneForm as default }
