import React, {useEffect} from 'react';
import Fade from '@material-ui/core/Fade';

import withFormik from "./model/withFormik";
import FormMinuterie from "./FormMinuterie";

import MailFormContext from '../../context/mailForm-context';

import {useSelector} from 'react-redux';
import { getArticlesList, getUserDetails, getUserType } from '../../redux/selectors';

import {useDispatch} from 'react-redux';

const ActualForm = withFormik(FormMinuterie);

const PrepareForm = (props) => {
  console.log(props)
  const dispatch = useDispatch();
  const userType = useSelector(getUserType);
  const userDetails = useSelector(getUserDetails);
  const articles = useSelector(getArticlesList);

  // useEffect(() => {
  //   props.close(props.closeDetails.temp, props.closeDetails.tempId);
  //   return function closeUpdate() {
  //     props.close(props.closeDetails.temp, props.closeDetails.tempId);
  //   }
  // }, []);
  
  useEffect(() => {
    return props.mail("assistenza.tecnica@muster-dikson.com");
  }, []);
  
  useEffect(() => {
    props.close("Settori", "settori");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);
  
  useEffect(() => {
    props.tipologia("Richiesta assistenza");
    return function closeUpdate() {
      props.tipologia("Richiesta assistenza");
    }
  }, []);

  return (
    <Fade in={props.checked}>
      <MailFormContext.Consumer>
        {value => {
          return (
            <ActualForm mailAssistenza={value.mail.mail} dispatch={dispatch} tipologiaId={props.closeDetails.currentId} 
              tipologia={value.mail.tipologia} checked={props.checked} 
              dati_utilizzatore={userType} userDetails={userDetails} articles={articles}/>
          )
        }}
      </MailFormContext.Consumer>
    </Fade>
  )
}
export { PrepareForm as default }
