import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import './Upload.css'
import Dropzone from '../dropzone/Dropzone';
import Progress from '../progress/Progress';
import Thumb from '../thumb/Thumb';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      uploadingFiles: []
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.onFileRemoved = this.onFileRemoved.bind(this);
  }

  makeid(length) {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  onFilesAdded(files) {
    files.forEach(file => {
      var artCodice = this.props.artCod;
      var id = this.makeid(5);
      file.frontEndId = artCodice+'-'+id+Date.now();
    });
    this.setState(prevState => ({
      files: prevState.files.concat(files),
      uploadingFiles: files
    }));
    this.uploadFiles(files);
  }

  onFileRemoved(backendId, frontEndId) {
    this.setState(prevState => ({
      files: prevState.files.filter(file => file.s3Id !== backendId)
    }));
    const uploadedFiles = this.props.uploadedFiles.filter(name => name !== backendId);
    this.props.setValue("uploadedFileName", uploadedFiles);
    const uploadedFileBlob = this.props.uploadedFileBlob.filter(name => name.frontEndId !== frontEndId);
    this.props.setValue(this.props.blobType, uploadedFileBlob);
    this.removeFile(backendId);
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderThumb(file) {
    return (
      <React.Fragment>
        <Thumb file={file} size="200px"/>
        <span className="Filename">{file.name}</span>
        <Button color="secondary" variant="contained"
          onClick={() => this.onFileRemoved(file.s3Id, file.frontEndId)}
        >
          Delete
        </Button>
      </React.Fragment>
    );
  }

  renderMicroThumb(file) {
    return (
      <React.Fragment>
        <Thumb file={file} size="100px"/>
      </React.Fragment>
    );
  }

  async removeFile(file) {
    try {
      await this.sendRemove(file);
      console.log("success");
    }
    catch(e) {
      console.log("errore");
    }
  }

  async uploadFiles(files) {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    files.forEach(file => {
      promises.push(
        this.sendRequest(file)
      );
    });
    try {
      await Promise.all(promises);
      setTimeout(
        () => {
          this.setState({ successfullUploaded: true, uploading: false, uploadingFiles:[] });
        }, 1000
      )
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false, uploadingFiles:[] });
    }
  }

  sendRemove(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open("DELETE", process.env.REACT_APP_BACKEND_URL+"/data/delete/"+file);
      req.setRequestHeader("Content-Type", "application/json");
      req.send(JSON.stringify({"fileToRemove": file}))
      req.onload = () => {
        console.log(req.response);
      }
    })
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
       if (event.lengthComputable) {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = {
         state: "pending",
         percentage: (event.loaded / event.total) * 100
        };
        this.setState({ uploadProgress: copy });
       }
      });

      req.upload.addEventListener("load", event => {
       const copy = { ...this.state.uploadProgress };
       copy[file.name] = { state: "done", percentage: 100 };
       this.setState({ uploadProgress: copy });
       resolve(req.response);
      });

      req.onload = () => {
        const returnedName = JSON.parse(req.response);
        this.setState(prevState => {
          var toBeUpdated = prevState.files.findIndex(prevFile => prevFile.frontEndId === file.frontEndId);
          let updatedObj = prevState.files.find(prevFile => prevFile.frontEndId === file.frontEndId);
          updatedObj.s3Id = returnedName.payload;
          const updatedProjects = [
            ...prevState.files.slice(0, toBeUpdated),
            updatedObj,
            ...prevState.files.slice(toBeUpdated + 1),
          ];
          return ({
            files: updatedProjects
          })
        });
        const uploadedFiles = this.props.uploadedFiles.concat(returnedName.payload);
        this.props.setValue("uploadedFileName", uploadedFiles);
        let fileToBeAdded = this.state.files.find(stateFile => stateFile.frontEndId === file.frontEndId);
        const uploadedFileBlob = this.props.uploadedFileBlob.concat(fileToBeAdded);
        this.props.setValue(this.props.blobType, uploadedFileBlob);
      };

      req.upload.addEventListener("error", event => {
       const copy = { ...this.state.uploadProgress };
       copy[file.name] = { state: "error", percentage: 0 };
       this.setState({ uploadProgress: copy });
       reject(req.response);
      });

      const formData = new FormData();
      const newFileName = this.props.artCod+'-'+file.name;
      formData.append("foto", file, newFileName);

      req.open("POST", process.env.REACT_APP_BACKEND_URL+"/data/image");
      req.send(formData);
    });
  }

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading}
            />
          </div>
          {this.state.uploadingFiles.length > 0 &&
            <h3>Files in caricamento...</h3>
          }
          <div className="Previews">
            {this.state.uploadingFiles.map(file => {
              return (
                <div key={file.frontEndId} className="Row">
                  {this.renderMicroThumb(file)}
                  {this.renderProgress(file)}
                </div>
              )
            })}
          </div>
          {this.props.uploadedFileBlob.length > 0 &&
            <h3>Files caricati</h3>
          }
          <div className="Files">
            {this.props.uploadedFileBlob.map(file => {
              return (
                <div key={file.frontEndId} className="Row" >
                  {this.renderThumb(file)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Upload
