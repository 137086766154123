import React, {useState, useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {
	FormikWizardProvider,
	Wizard,
	StepsList,
	Step,
	ButtonsList,
	PreviousButton,
	NextButton,
	SubmitButton
} from  "formik-wizard-form";
import Hidden from '@material-ui/core/Hidden';
import { DisplayFormikState } from './model/helper';
import RMA from './steps/RMA';
import RMA2 from './steps/RMA2';
import RiepilogoRMA from './steps/RiepilogoRMA';
import FormSubmitted from './FormSubmitted';
import './form.css';
import { getUserType, getArticlesList } from '../../redux/selectors';
import {setArticlesApi,addArticleApi, deleteAllArticles} from '../../redux/actions/actions';

function datiValidations({ errors }) {
  return !errors.noleggio_oppure_Acquisto
		&& !errors.numero_contratto && !errors.societa_noleggio
		&& !errors.rivenditore_presente	&& !errors.ragione_sociale_rivenditore
		&& !errors.citta_rivenditore && !errors.piva_rivenditore
		// && !errors.tipo_identificativo 
		// && !errors.input_pec && !errors.input_codice_univoco
}

function articoloValidations({ errors }) {
	return !errors.codice_articolo
	&& !errors.matricola_o_lotto_articolo
	&& !errors.articolo_in_Garanzia
	&& !errors.descrizione_difetto && !errors.numero_pezzi 
	&& !errors.allega_foto && !errors.trasporto_a_cura_di 
	&& !errors.indirizzo_ritiro && !errors.citta_ritiro
	&& !errors.provincia_ritiro && !errors.cap_ritiro
	&& !errors.telefono_ritiro
	&& !errors.add_article
	
}

const FormRMA = (props) => {
	const values = props.values;
	const setFieldValue = props.setFieldValue;
	const [nextButtonText, setNextButtonText] = useState('Avanti');
	const [isHidden, setIsHidden] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		props.setFieldValue("mailAssistenza", props.mailAssistenza);
	}, []);
	useEffect(() => {
		props.setFieldValue("tipologia_richiesta", props.tipologiaId);
	}, []);

	const dispatch = useDispatch();
  const articles = useSelector(getArticlesList);
	const [stepId, setStepId] = useState(1);
  const [addArticleButton, setAddArticleButton] = useState(false);
  const [isTextHidden, setIsTextHidden] = useState(false);

  const addNewArticle = (articleId) => {
    setAddArticleButton(false);
		let newArticle = {
			id: articleId,
			codice_articolo: values.codice_articolo,
			nome_articolo: values.nome_articolo,
			descrizione_articolo: values.descrizione_articolo,
			matricola_o_lotto_articolo: values.matricola_o_lotto_articolo,
			articolo_in_Garanzia: values.articolo_in_Garanzia,
			uploadedFileName: values.uploadedFileName,
			uploadedGaranzia: values.uploadedGaranzia,
			uploadedDifetto: values.uploadedDifetto,
			// descrizione_guasto: values.descrizione_guasto,
			// quando_si_verifica_il_problema: values.quando_si_verifica_il_problema,
			// descrizione_casistica: values.descrizione_casistica,
			// descrizione_tentativi: values.descrizione_tentativi,
			allega_foto: values.allega_foto,
			descrizione_difetto: values.descrizione_difetto,
			numero_pezzi: values.numero_pezzi,
		}

		setFieldValue('codice_articolo', '');
    setFieldValue('nome_articolo', '');
    setFieldValue('descrizione_articolo', '');
    setFieldValue('matricola_o_lotto_articolo', '');
    setFieldValue('articolo_in_Garanzia', '');
    setFieldValue('uploadedFileName', []);
    // setFieldValue('descrizione_guasto', '');
    // setFieldValue('quando_si_verifica_il_problema', '');
    // setFieldValue('descrizione_casistica', '');
    // setFieldValue('descrizione_tentativi', '');
    setFieldValue('allega_foto', '');
    setFieldValue('descrizione_difetto', '');
    setFieldValue('numero_pezzi', '');
    setFieldValue('add_article', 'ok');
		setFieldValue('uploadedGaranzia', []);
    setFieldValue('uploadedDifetto', []);
		setIsHidden(false);
		setIsTextHidden(false);
		dispatch(addArticleApi(newArticle));
  }

	useEffect(()=> {
		(values.codice_articolo == '' || !values.matricola_o_lotto_articolo || !values.descrizione_difetto || !values.numero_pezzi || !values.articolo_in_Garanzia || !values.allega_foto) ? setAddArticleButton(false) : setAddArticleButton(true);
  }, [values.codice_articolo, values.matricola_o_lotto_articolo, values.descrizione_difetto, values.numero_pezzi, values.articolo_in_Garanzia, values.allega_foto]);

  useEffect(()=> {
		dispatch(deleteAllArticles({}));
  }, []);
	
	return(
	  <React.Fragment>
		{ !props.isSubmitting ? (
			<React.Fragment>
				<p>
					La scheda può essere compilata per
					<strong> molteplici articoli</strong>.
				</p>
				<FormikWizardProvider {...props}>
				{({ getValidators, ...otherProps }) => (
					<Wizard {...otherProps}>
					<StepsList
						validators={getValidators(
						[datiValidations, articoloValidations]
						)}
					>
						<Step
							component={RMA} setNextButtonText={setNextButtonText}
							checked={props.checked} title="Dati" setIsHidden={setIsHidden} setStepId={setStepId}
						/>
						<Step
							component={RMA2} setNextButtonText={setNextButtonText}
							checked={props.checked} title="Articolo" addNewArticle={addNewArticle}
							addArticleButton={addArticleButton} setIsHidden={setIsHidden} 
							isTextHidden={isTextHidden} setIsTextHidden={setIsTextHidden} setStepId={setStepId}
						/>
						<Step
							component={RiepilogoRMA}
							checked={props.checked} title="Controlla" setStepId={setStepId}
							isChecked={isChecked} setIsChecked={setIsChecked}
						/>
					</StepsList>
					<ButtonsList>
						<PreviousButton label="Indietro" />
						{stepId === 2 && articles.length === 0 ? 
							<Hidden xsUp>
								<NextButton label={nextButtonText} />
							</Hidden>
							: <NextButton label={nextButtonText} />
						}
						{isChecked ?
							<SubmitButton label="INVIA" /> :
							<Hidden xsUp>
								<SubmitButton label="INVIA" />
							</Hidden>
						}
					</ButtonsList>
					</Wizard>
				)}
				</FormikWizardProvider>
				{/* <DisplayFormikState {...props} /> */}
			</React.Fragment>
		) : (<FormSubmitted props={props}/>)}
	  </React.Fragment>
	)
};

export { FormRMA as default};
