import { combineReducers } from "redux";
import userTypeFilter from "./userTypeFilter";
import userType from "./userType";
import userTouchedFields from "./userTouchedFields";
import articles from "./articles";
import settings from "./settings";

export default combineReducers({ 
  userType, 
  userTypeFilter,
  userTouchedFields,
  articles,
  settings
});
