import React, {useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fade from '@material-ui/core/Fade';

const HelpDesk = (props) => {
  useEffect(() => {
    props.close(props.closeDetails.temp, props.closeDetails.tempId);
    return function closeUpdate() {
      props.close(props.closeDetails.temp, props.closeDetails.tempId);
    }
  }, []);

  return (<>
    <div>
      <br/><br/>
      {/* <p>Per un problema tecnico oppure per informazioni su una procedura d'assistenza in corso:</p> */}
      {/* <Card style={{backgroundColor: "#f1f1f1"}}>
        <CardContent>
          <h2>Servizio assistenza tecnici Müster:</h2>
          <h3>Daniele Arvani</h3>
          <p>
            Tel: <strong> 335 8470123</strong><br/>
            Mail: <strong> daniele.arvani@muster-dikson.com</strong>
          </p><br/>
          <h3>Paolo Mignoli</h3>
          <p>
            Tel: <strong> 335 1295356</strong><br/>
            Mail: <strong> paolo.mignoli@muster-dikson.com</strong>
          </p>
        </CardContent>
      </Card> */}
      {/* <br/><br/> */}
      <Card style={{backgroundColor: "#f1f1f1"}}>
        <CardContent>
          <h2>Assistenza:</h2>
          {/* <p>Per ricevere informazioni sulla compilazione del form online, puoi chiamare il nostro numero verde</p> */}
          Tel: <strong> 800 265330</strong><br /><br />
          Mail: <strong> assistenza.tecnica@muster-dikson.com</strong>
        </CardContent>
      </Card>
  </div>
  </>)
}
export { HelpDesk as default }
