import {
  SET_USERTOUCHEDFIELDS
} from '../actions/actionTypes';

const initialState = {
  ragione_sociale: false,
  nome_e_cognome: false,
  partita_iva: false,
  codice_fiscale: false,
  telefono: false,
  email: false,
  tipo_identificativo: false,
  input_codice_univoco: false,
  input_pec: false
};

export default function (state = initialState, action) {
  switch(action.type) {
    case SET_USERTOUCHEDFIELDS: {
      const userTouchedFields = action.payload;
      let ragione_sociale, nome_e_cognome, partita_iva, codice_fiscale = '';
      ragione_sociale = userTouchedFields.ragione_sociale ? userTouchedFields.ragione_sociale : '';
      nome_e_cognome = userTouchedFields.nome_e_cognome ? userTouchedFields.nome_e_cognome : '';
      partita_iva = userTouchedFields.partita_iva ? userTouchedFields.partita_iva : '';
      codice_fiscale = userTouchedFields.codice_fiscale ? userTouchedFields.codice_fiscale : '';
      return {
        ...state,
        ragione_sociale,
        nome_e_cognome,
        partita_iva,
        codice_fiscale,
        telefono: userTouchedFields.telefono,
        email: userTouchedFields.email,
        input_codice_univoco: userTouchedFields.input_codice_univoco,
        input_pec: userTouchedFields.input_pec,
      }
    }
    default:
      return state;
  }
}