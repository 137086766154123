import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import NavigationContext from '../../context/navigation-context';

const Settore = (props) => {
  const [stateSettore, setSettore] = React.useState(false);
  function handleClick() {
    setSettore(!stateSettore);
  }

  const settoreList = {
    backgroundColor: '#eaeaea',
    margin: '10px 0'
  }
  
  return (
    <React.Fragment>
      <ListItem style={settoreList} button onClick={() => (handleClick())}>
        <ListItemText primary={props.tit} />
        {stateSettore ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={stateSettore} timeout="auto" unmountOnExit>
        {props.cat.map((settore) => {
          return(
            <SottoSettore key={settore.id} id={settore.id} name={settore.nome}
            classes={props.classes} description={settore.description}/>
          )
        })}

      </Collapse>
    </React.Fragment>
  )
};

const SottoSettore = (props) => {
  return (
    <NavigationContext.Consumer>
    {value => {
      const click = () => value.handleNavigation(props.name, props.id, "Settori", "settori");
      return(
        <React.Fragment>
          <List component="div" disablePadding>
            <ListItem button divider className={props.classes.nested} onClick={click}>
              <ListItemText 
                primary={props.name} secondary={props.description}
              />
            </ListItem>
          </List>
        </React.Fragment>
      )
    }}
    </NavigationContext.Consumer>
  )
};

export { Settore, SottoSettore }
