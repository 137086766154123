import React, { useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import SelezioneForm from './SelezioneForm';
import AssistenzaTelefonica from '../AssistenzaTelefonica';

import NavigationContext from '../../context/navigation-context';

const SelezioneProblema = (props) => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    props.close("Settori", "settori");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);
  useEffect(() => {
    props.temp(props.closeDetails.current, props.closeDetails.currentId);
    return function closeUpdate() {
      props.temp(props.closeDetails.current, props.closeDetails.currentId);
    }
  }, []);

  const [stateProblemaTecnico, setProblemaTecnico] = React.useState(false);
  function handleTecnico() {
    setProblemaTecnico(!stateProblemaTecnico);
    setProblemaUtilizzo(false);
  }
  const [stateProblemaUtilizzo, setProblemaUtilizzo] = React.useState(false);
  function handleUtilizzo() {
    setProblemaUtilizzo(!stateProblemaUtilizzo);
    setProblemaTecnico(false);
  }

  return (
    <Fade in={props.checked}>
      <NavigationContext.Consumer>
        {value => {
          const click = (current, currentId) => value.handleNavigation(
            current, currentId, props.closeDetails.close, props.closeDetails.closeId
          );
          return (
            <React.Fragment>
              <List
                component="div" disablePadding
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Che tipo di problema hai riscontrato?
                  </ListSubheader>
                }
              >
                <ListItem button onClick={() => (handleTecnico())}>
                  <ListItemText primary="Problema tecnico" />
                  {stateProblemaTecnico ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={stateProblemaTecnico} timeout="auto" unmountOnExit>
                  <SelezioneForm checked={props.checked} mail={props.mail} temp={props.temp} close={props.close} closeDetails={props.closeDetails} />
                  <ListItem button className={classes.nested} onClick={
                    ()=>click("Help Desk", "helpde")
                  }>
                    <ListItemText primary="Help Desk" />
                  </ListItem>
                </Collapse>
                <ListItem button onClick={() => (handleUtilizzo())}>
                  <ListItemText primary="Problema di utilizzo" />
                  {stateProblemaUtilizzo ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={stateProblemaUtilizzo} timeout="auto" unmountOnExit>
                  <AssistenzaTelefonica />
                </Collapse>
              </List>
            </React.Fragment>
          )
        }}
      </NavigationContext.Consumer>
    </Fade>
  )
}
export { SelezioneProblema as default }
