import {
  SET_DOCUMENT
} from '../actions/actionTypes';

const initialState = {
  document: ''
};

export default function (state = initialState, action) {
  switch(action.type) {
    case SET_DOCUMENT: {
      const {doc} = action.payload;
      return {
        ...state,
        document: doc
      }
    }
    default:
      return state;
  }
}