import React, { useEffect } from "react";
import {useSelector} from 'react-redux';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import { Field } from "formik";
import classNames from "classnames";
import { getUserType } from '../../../redux/selectors';

import '../../form.css';

const useStyles = makeStyles(theme => ({
  root: {
  },
  formControl: {
    margin: theme.spacing(2)
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const InputFeedback = ({error}) =>
  error ? <div className={classNames("input-feedback")}>{error}</div> : null;

const RadioNoleggioAcquisto =({
  field:  { name, value, onChange, onBlur },
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio/>}
    checked={id === value} onChange={onChange} onBlur={onBlur} onClick={props.soc}
  />
)
const RadioNoleggioAcquistoGroup = ({
  value, error, touched, id, label, className, children
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup className={classes.group}>
        {children}
        {touched && <InputFeedback error={error} />}
      </RadioGroup>
    </FormControl>
  )
}

const RadioSocietaNoleggio =({
  field:  { name, value, onChange, onBlur },
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio/>}
    checked={id === value} onChange={onChange} onBlur={onBlur}
  />
)
const RadioSocietaNoleggioGroup = ({
  value, error, touched, id, label, className, children
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup className={classes.group}>
        {children}
        {touched && <InputFeedback error={error} />}
      </RadioGroup>
    </FormControl>
  )
}

const RadioRivenditore =({
  field:  { name, value, onChange, onBlur },
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio />}
    checked={id === value} onChange={onChange} onBlur={onBlur}
  />
)

const RadioRivenditoreGroup = ({
  value, error, touched, id, label, className, children
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup className={classes.group}>
        {children}
        {touched && <InputFeedback error={error} />}
      </RadioGroup>
    </FormControl>
  )
}

const CustomTextField = ({field, label}) => {
  return (
  <TextField {...field} label={label} variant="filled" />
  )
}

const SegnalazioneProblema = ({touched, errors, values, ...props}) => {
  const classes = useStyles();
  const userType = useSelector(getUserType);
	const tipologiaUtente = userType;
  
  useEffect(()=> {
    window.scrollTo(0,0);
    props.setNextButtonText('Avanti');
    props.setIsHidden(false);
    props.setStepId(1);
  }, []);

  return (
    <Fade in={props.checked}>
      <div className={classes.root}>
        {tipologiaUtente !== 'privato' &&
          <>
            <h2>Dati generali</h2>
            <RadioNoleggioAcquistoGroup 
              label="Hai scelto il noleggio operativo o hai acquistato l'articolo?"
              id="noleggioAcquisto" value={values.radioGroup}
              error={errors.radioGroup} touched={touched.radioGroup}
            >
              <Field component={RadioNoleggioAcquisto} name="noleggio_oppure_Acquisto"
                id="noleggio" label="Noleggio operativo"
              />
              <Field component={RadioNoleggioAcquisto} name="noleggio_oppure_Acquisto"
                id="acquisto" label="Acquisto"
              />
            </RadioNoleggioAcquistoGroup>
          </>
        }
        <br />
        <br />


        {/* NOLEGGIO  */}
        {values.noleggio_oppure_Acquisto === "noleggio" &&
          <React.Fragment>
            <h2>Noleggio Operativo</h2>
            <FormControl className={classes.formControl}>
              <Field name="numero_contratto" label="N° Contratto"
                component={CustomTextField}
              />
              {touched.numero_contratto &&
                typeof errors.numero_contratto === "string" &&
                errors.numero_contratto}
            </FormControl>
            {/* <FormControl className={classes.formControl}>
              <Field name="ragione_sociale" label="Ragione sociale"
                component={CustomTextField}
              />
              {touched.ragione_sociale &&
                typeof errors.ragione_sociale === "string" &&
                errors.ragione_sociale}
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field name="partita_iva" label="P. IVA"
                component={CustomTextField}
              />
              {touched.partita_iva &&
                typeof errors.partita_iva === "string" &&
                errors.partita_iva}
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field name="telefono" label="Telefono"
                component={CustomTextField}
              />
              {touched.telefono &&
                typeof errors.telefono === "string" &&
                errors.telefono}
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field name="email" label="Email"
                component={CustomTextField}
              />
              {touched.email &&
                typeof errors.email === "string" &&
                errors.email}
            </FormControl> */}
            <br />
            <br />
            <h2>Società di noleggio</h2>
            <RadioSocietaNoleggioGroup 
              label="Seleziona la società con cui hai sottoscritto il contratto"
              id="societaNoleggio" value={values.radioGroup}
              error={errors.radioGroup} touched={touched.radioGroup}
            >
              <Field component={RadioSocietaNoleggio} name="societa_noleggio"
                id="grenke" label="Grenke"
              />
              <Field component={RadioSocietaNoleggio} name="societa_noleggio"
                id="euroconsult" label="Euroconsult"
              />
              <Field component={RadioSocietaNoleggio} name="societa_noleggio"
                id="crisfin" label="Crisfin"
              />
              <Field component={RadioSocietaNoleggio} name="societa_noleggio"
                id="intesa_san_paolo" label="Intesa San Paolo Rent for You"
              />
            </RadioSocietaNoleggioGroup>

            <br />
            <br />
          </React.Fragment>
        }


        {/* ACQUISTO  */}
        {(values.noleggio_oppure_Acquisto === "acquisto" || tipologiaUtente === "privato") &&
          <React.Fragment>
            {tipologiaUtente !== "distributore" &&
              <>
                <h2>Dati Acquisto</h2>
                <RadioRivenditoreGroup
                  id="rivenditore" label="È presente il rivenditore?"
                  value={values.radioGroup}
                  error={errors.radioGroup} touched={touched.radioGroup}
                >
                  <Field component={RadioRivenditore} name="rivenditore_presente"
                    id="rivenditoreSi" label="Sì"
                  />
                  <Field component={RadioRivenditore} name="rivenditore_presente"
                    id="rivenditoreNo" label="No"
                  />
                </RadioRivenditoreGroup>
                {values.rivenditore_presente === "rivenditoreSi" &&
                  <React.Fragment>
                    <h2>Dati Rivenditore</h2>
                    <p>I campi qui sotto fanno riferimento al rivenditore: questi dati li puoi rilevare dalla fattura di acquisto o dal DDT di consegna</p>
                    <FormControl className={classes.formControl}>
                      <Field name="ragione_sociale_rivenditore" label="Ragione sociale"
                        component={CustomTextField}
                      />
                      {touched.ragione_sociale_rivenditore &&
                        typeof errors.ragione_sociale_rivenditore === "string" &&
                        errors.ragione_sociale_rivenditore}
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field name="piva_rivenditore" label="P.IVA"
                        component={CustomTextField}
                      />
                      {touched.piva_rivenditore &&
                        typeof errors.piva_rivenditore === "string" &&
                        errors.piva_rivenditore}
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field name="citta_rivenditore" label="Città"
                        component={CustomTextField}
                      />
                      {touched.citta_rivenditore &&
                        typeof errors.citta_rivenditore === "string" &&
                        errors.citta_rivenditore}
                    </FormControl>
                  </React.Fragment>
                }
              </>
            }
          </React.Fragment>
        }
        <br />
        <br />
      </div>
    </Fade>
  )
};

export { SegnalazioneProblema as default}
