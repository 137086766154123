import {
  SET_USERTYPE,
  SET_TIPOIDENTIFICATIVO, 
  SET_ARTICLES,
  SET_SELECTED_ARTICLE,
  SET_USERDETAILS, 
  SET_USERTOUCHEDFIELDS,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
  DELETE_ALL_ARTICLES,
  ADD_ARTICLE,
  SET_DOCUMENT,
} from './actionTypes';

export const setUserType = userType => ({
  type: SET_USERTYPE,
  payload: {
    userType
  }
});

export const setTipoIdentificativo = tipoIdentificativo => ({
  type: SET_TIPOIDENTIFICATIVO,
  payload: {
    tipoIdentificativo
  }
});

export const setUserDetails = (userDetails) => ({
  type: SET_USERDETAILS,
  payload: {
    ...userDetails
  }
});

export const setUserTouchedFields = (userTouchedFields) => ({
  type: SET_USERTOUCHEDFIELDS,
  payload: {
    ...userTouchedFields
  }
});

export const setArticlesApi = articles => ({
  type: SET_ARTICLES,
  payload: {
    articles
  }
})

export const setSelectedArticle = id => ({
  type: SET_SELECTED_ARTICLE,
  payload: {
    id
  }
})

export const editArticle = article => ({
  type: EDIT_ARTICLE,
  payload: {
    article
  }
})

export const deleteArticle = id => ({
  type: DELETE_ARTICLE,
  payload: {
    id
  }
})

export const deleteAllArticles = () => ({
  type: DELETE_ALL_ARTICLES,
  payload: {}
})

export const addArticleApi = article => ({
  type: ADD_ARTICLE,
  payload: {
    article
  }
})

export const setDocument = doc => ({
  type: SET_DOCUMENT,
  payload: {
    doc
  }
})