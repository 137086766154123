import {
  SET_ARTICLES,
  SET_SELECTED_ARTICLE,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
  DELETE_ALL_ARTICLES,
  ADD_ARTICLE
} from '../actions/actionTypes';

const initialState = {
  type: [],
  selectedArticle: {},
  // type: [
  //   {
  //     id: 0,
  //     codice_articolo:"324",
  //     nome_articolo:"sdf",
  //     descrizione_articolo:"fsd",
  //     matricola_o_lotto_articolo:"32424352532235523",
  //     numero_pezzi: '12',
  //     articolo_in_Garanzia:"garanziaNo",
  //     uploadedFileName: '',
  //     uploadedGaranzia: '',
  //     descrizione_guasto:"sdf",
  //     quando_si_verifica_il_problema:"occasionalmente",
  //     descrizione_casistica:"dsf",
  //     descrizione_tentativi:"sdf"
  //   },
  //   {
  //     id: 1,
  //     codice_articolo:"23324",
  //     nome_articolo:"sdasdfdf",
  //     descrizione_articolo:"sdffsd",
  //     matricola_o_lotto_articolo:"32424352532235523",
  //     numero_pezzi: '12',
  //     articolo_in_Garanzia:"garanziaNo",
  //     uploadedFileName: '',
  //     uploadedGaranzia: '',
  //     descrizione_guasto:"sdf",
  //     quando_si_verifica_il_problema:"occasionalmente",
  //     descrizione_casistica:"dsf",
  //     descrizione_tentativi:"sdf"
  //   }
  // ],
  // selectedArticle: {
  //   id: 0,
  //   codice_articolo:"1111",
  //   nome_articolo:"aaa",
  //   descrizione_articolo:"aaa",
  //   matricola_o_lotto_articolo:"32424352532235523",
  //   articolo_in_Garanzia:"garanziaNo",
  //   uploadedFileName: '',
  //   uploadedGaranzia: '',
  //   descrizione_guasto:"sdf",
  //   quando_si_verifica_il_problema:"occasionalmente",
  //   descrizione_casistica:"dsf",
  //   descrizione_tentativi:"sdf"
  // }
};

export default function (state = initialState, action) {
  switch(action.type) {
    case SET_ARTICLES: {
      const {articles} = action.payload;
      return {
        ...state,
        type: articles
      }
    }
    case ADD_ARTICLE: {
      const {article} = action.payload;
      return {
        ...state,
        type: [
          article,
          ...state.type
        ]
      }
    }
    case SET_SELECTED_ARTICLE: {
      const {id} = action.payload;
      return {
        ...state,
        selectedArticle: state.type.filter(article => article.id == id)
      }
    }
    case EDIT_ARTICLE: {
      const {article} = action.payload;
      console.log(article[0].id);
      return {
        ...state,
        type: state.type.map(el => el.id === article[0].id ? article[0] : el)
      }
    }
    case DELETE_ARTICLE: {
      const {id} = action.payload;
      return {
        ...state,
        type: state.type.filter(article => article.id !== id)
      }
    }
    case DELETE_ALL_ARTICLES: {
      return {
        ...state,
        type: [],
        selectedArticle: {}
      }
    }
    default:
      return state;
  }
}