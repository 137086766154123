import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setArticlesApi, setSelectedArticle} from '../../redux/actions/actions';
import {getDocument} from '../../redux/selectors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  downloadButton: {
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: '5px',
    backgroundColor: '#13518b',
    padding: '10px 20px',
    color: 'white',
    margin: theme.spacing(1),
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  },
}));

const FormSubmitted = (props) => {
  const dispatch = useDispatch();
  const document = useSelector(getDocument);
  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatch(setSelectedArticle({}));
      dispatch(setArticlesApi([]));
    }
  },[]);

  return(
    <React.Fragment>
      <h2>Form inviato con successo!</h2>
      <p>Grazie,<br/>
        la tua richiesta è stata presa in carico.
      </p>
      <p>Sarai contattato dal nostro help desk entro 24/48 ore (nei giorni lavorativi).</p>
      <p>Se lo desideri puoi salvare la scheda riassuntiva della tua richiesta, che troverai comunque in allegato alla mail di notifica.</p>
      <a href={document} target='_blank' rel="noopener" className={classes.downloadButton}>
        Scarica scheda riassuntiva
      </a>
    </React.Fragment>
  );
}

export { FormSubmitted as default };
