import React, {useEffect} from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  
  title: {
    textAlign: 'center'
  },
  buttonContainer: {
    textAlign: 'center'
  },
  myButton: {
    backgroundColor: '#13518b',
    color: 'white',
    margin: theme.spacing(1),
    padding: '20px 30px 20px 30px',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  },
}));

const Start = (props) => {
  const classes = useStyles();

  const start = () => {
    props.navigate();
  }

  return (
    <Fade in={props.checked}>
      <div>
        <h2 className={classes.title}>Benvenuto nel servizio Assistenza Clienti<br/>Müster & Dikson</h2>
        <div>
          <br/><br/>
          <h4>Puoi effettuare una segnalazione problema o una richiesta di reso compilando i moduli del nostro servizio online:</h4>
              <div className={classes.buttonContainer}>
                <Button size="large" color="default" className={classes.myButton}
                    onClick={start}>
                    Inizia
                </Button>
              </div>
          <br/><br/>
          <h4>In alternativa, puoi contattare l'assistenza</h4>
          {/* <Card style={{backgroundColor: "#f1f1f1"}}>
            <CardContent>
              <h2>Servizio assistenza tecnici Müster:</h2>
              <h3>Daniele Arvani</h3>
              <p>
                Tel: <strong> 335 8470123</strong><br/>
                Mail: <strong> daniele.arvani@muster-dikson.com</strong>
              </p><br/>
              <h3>Paolo Mignoli</h3>
              <p>
                Tel: <strong> 335 1295356</strong><br/>
                Mail: <strong> paolo.mignoli@muster-dikson.com</strong>
              </p>
            </CardContent>
          </Card>
          <br/><br/> */}
          <Card style={{backgroundColor: "#f1f1f1"}}>
            <CardContent>
              <h2>Assistenza:</h2>
              {/* <p>Per ricevere informazioni sulla compilazione del form online, puoi chiamare il nostro numero verde</p> */}
              Tel: <strong> 800 265330</strong><br /><br />
              Mail: <strong> assistenza.tecnica@muster-dikson.com</strong>
            </CardContent>
            {/* <CardContent>
              <h2>Assistenza telefonica:</h2>
              <p>Per ricevere informazioni sulla compilazione del form online, puoi chiamare il nostro numero verde</p>
              Tel: <strong> 800 265330</strong><br /><br />
            </CardContent> */}
          </Card>
        </div>

      </div>
    </Fade>
  )
}
export { Start as default }
