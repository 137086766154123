import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from "classnames";
import { Field } from "formik";
import TextField from '@material-ui/core/TextField';
import NavigationContext from '../context/navigation-context';
import {setUserType, setUserDetails, setUserTouchedFields, setTipoIdentificativo} from '../redux/actions/actions';
import { getUserType, getUserDetails, getUserTouchedFields } from '../redux/selectors';

const useStyles = makeStyles(theme => ({
  root: {
  },
  formControl: {
    margin: theme.spacing(2)
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const TipoUtente = (props) => {
  let localStorage = window.localStorage;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userType = useSelector(getUserType);
  const userDetails = useSelector(getUserDetails);
  const userTouchedFields = useSelector(getUserTouchedFields);

  const [value, setValue] = useState('');
  const [fatturazioneValue, setFatturazioneValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  let [userDetailsState, setUserDetailsState] = useState({
    ragione_sociale: '',
    nome_e_cognome: '',
    partita_iva: '',
    codice_fiscale: '',
    telefono: '',
    email: '',
    tipo_identificativo: '',
    input_codice_univoco: '',
    input_pec: '',
  });
  const [validEmail, setValidEmail] = useState(false);
  const [validPec, setValidPec] = useState(false);
  let [userDetailsTouchedState, setUserDetailsTouchedState] = useState({
    ragione_sociale: false,
    nome_e_cognome: false,
    partita_iva: false,
    codice_fiscale: false,
    telefono: false,
    email: false,
    tipo_identificativo: false,
    input_codice_univoco: false,
    input_pec: false
  });

  useEffect(() => {
    setUserDetailsState({
      type: userDetails.type,
      ragione_sociale: userDetails.ragione_sociale !== '' ? userDetails.ragione_sociale : localStorage.getItem('ragione_sociale') ? localStorage.getItem('ragione_sociale') : '',
      nome_e_cognome: userDetails.nome_e_cognome !== '' ? userDetails.nome_e_cognome : localStorage.getItem('nome_e_cognome') ? localStorage.getItem('nome_e_cognome') : '',
      partita_iva: userDetails.partita_iva !== '' ? userDetails.partita_iva : localStorage.getItem('partita_iva') ? localStorage.getItem('partita_iva') : '',
      codice_fiscale: userDetails.codice_fiscale !== '' ? userDetails.codice_fiscale : localStorage.getItem('codice_fiscale') ? localStorage.getItem('codice_fiscale') : '',
      telefono: userDetails.telefono !== '' ? userDetails.telefono : localStorage.getItem('telefono') ? localStorage.getItem('telefono') : '',
      email: userDetails.email !== '' ? userDetails.email : localStorage.getItem('email') ? localStorage.getItem('email') : '',
      tipo_identificativo: userDetails.tipo_identificativo,
      input_codice_univoco: userDetails.input_codice_univoco !== '' ? userDetails.input_codice_univoco : localStorage.getItem('input_codice_univoco') ? localStorage.getItem('input_codice_univoco') : '',
      input_pec: userDetails.input_pec !== '' ? userDetails.input_pec : localStorage.getItem('input_pec') ? localStorage.getItem('input_pec') : '',
    });
    setUserDetailsTouchedState({
      ragione_sociale: userTouchedFields.ragione_sociale,
      nome_e_cognome: userTouchedFields.nome_e_cognome,
      partita_iva: userTouchedFields.partita_iva,
      codice_fiscale: userTouchedFields.codice_fiscale,
      telefono: userTouchedFields.telefono,
      email: userTouchedFields.email,
      tipo_identificativo: userTouchedFields.tipo_identificativo,
      input_codice_univoco: userTouchedFields.input_codice_univoco,
      input_pec: userTouchedFields.input_pec
    });
    setValue(userDetails.type);
    setFatturazioneValue(userDetails.tipo_identificativo);
  }, [])

  const handleChange = (event) => {
    let value = event.target.value;
    setValue(value);
    dispatch(setUserType(value));
  }

  const handleTipoIdentificativo = (event) => {
    let value = event.target.value;
    setFatturazioneValue(value);
    setUserDetailsState({
      ...userDetailsState,
      tipo_identificativo: value
    });
    _setTouched('tipo_identificativo');
    dispatch(setTipoIdentificativo(value));
  }

  const handleUser = (event, type) => {
    let value = event.target.value
    setUserDetailsState({
      ...userDetailsState,
      [type]: value
    });
  }
  
  const _setUserDetails = () => {
    dispatch(setUserDetails(userDetailsState));
    dispatch(setUserTouchedFields(userDetailsTouchedState));
    localStorage.setItem('ragione_sociale', userDetailsState.ragione_sociale);
    localStorage.setItem('nome_e_cognome', userDetailsState.nome_e_cognome);
    localStorage.setItem('partita_iva', userDetailsState.partita_iva);
    localStorage.setItem('codice_fiscale', userDetailsState.codice_fiscale);
    localStorage.setItem('telefono', userDetailsState.telefono);
    localStorage.setItem('email', userDetailsState.email);
    localStorage.setItem('input_pec', userDetailsState.input_pec);
    localStorage.setItem('input_codice_univoco', userDetailsState.input_codice_univoco);
  }
  
  const _setTouched = (type) => {
    setUserDetailsTouchedState({
      ...userDetailsTouchedState,
      [type]: true
    });
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  useEffect(()=> {
    if(userType) {
      if(userType === 'privato') {
        // userDetailsTouchedState.nome_e_cognome && userDetailsTouchedState.codice_fiscale &&
        // userDetailsTouchedState.telefono && userDetailsTouchedState.email &&
        userDetailsState.nome_e_cognome !== '' && userDetailsState.codice_fiscale !== '' &&
        userDetailsState.telefono !== '' && userDetailsState.email !== '' &&
        validateEmail(userDetailsState.email) ?
        setIsDisabled(false) : setIsDisabled(true);
      } else if (userType === 'distributore') {
        // userDetailsTouchedState.ragione_sociale && userDetailsTouchedState.partita_iva &&
        // userDetailsTouchedState.telefono && userDetailsTouchedState.email &&
        userDetailsState.ragione_sociale !== '' && userDetailsState.partita_iva !== '' &&
        userDetailsState.telefono !== '' && userDetailsState.email !== '' &&
        validateEmail(userDetailsState.email) && userDetailsState.partita_iva.length === 11 ?
        setIsDisabled(false) : setIsDisabled(true);
      } else if (userType === 'partita_iva') {
        if(
          // userDetailsTouchedState.ragione_sociale && userDetailsTouchedState.partita_iva &&
          // userDetailsTouchedState.telefono && userDetailsTouchedState.email &&
          userDetailsState.ragione_sociale !== '' && userDetailsState.partita_iva !== '' &&
          userDetailsState.telefono !== '' && userDetailsState.email !== '' &&
          validateEmail(userDetailsState.email) && userDetailsState.partita_iva.length === 11 &&
          userDetailsTouchedState.tipo_identificativo && userDetailsState.tipo_identificativo !== '') {
          // console.log(userDetailsState.tipo_identificativo);
          if(userDetailsState.tipo_identificativo === 'codice_univoco') {
            // console.log('codice_univoco', isDisabled);
            // userDetailsTouchedState.input_codice_univoco && userDetailsState.input_codice_univoco !== '' ?
            userDetailsState.input_codice_univoco !== '' ?
            setIsDisabled(false) : setIsDisabled(true);
          } else if(userDetailsState.tipo_identificativo === 'pec') {
            // console.log('pec', isDisabled);
            // userDetailsTouchedState.input_pec && userDetailsState.input_pec !== '' ?
            userDetailsState.input_pec !== '' ?
            setIsDisabled(false) : setIsDisabled(true);
          } else if(userDetailsState.tipo_identificativo === 'forfettario') {
            // console.log('forfettario');
            setIsDisabled(false);
          }
        } else {
          // console.log('not ready');
          setIsDisabled(true)
        }
      }
    }
    userDetailsState.email && validateEmail(userDetailsState.email) ? setValidEmail(true) : setValidEmail(false);
		// (values.articolo == '' || !values.quando_si_verifica_il_problema || !values.articolo_in_Garanzia) 
    //   ? setIsDisabled(false) : setIsDisabled(true);
  }, [userDetailsState, userDetailsTouchedState, userType]);
  
  // useEffect(()=> console.log(userDetailsTouchedState), [userDetailsTouchedState])
  
  return(
    <Fade in={props.checked}>
      <>
        <div className={classes.root}>
          <h2>Dati generali</h2>
          <FormControl component="fieldset">
            <FormLabel component="legend">Sono un:</FormLabel>
            <RadioGroup aria-label="tipo" name="tipo" value={value} onChange={handleChange}>
              <FormControlLabel value="partita_iva" control={<Radio />} label="Professionista con partita iva (acconciatore/estetista)" />
              <FormControlLabel value="distributore" control={<Radio />} label="Distributore/grossista/rivenditore" />
              <FormControlLabel value="privato" control={<Radio />} label="Privato senza partita iva" />
            </RadioGroup>
          </FormControl>
          {userType &&
            <>
              {(userType === 'partita_iva' || userType === 'distributore') &&
                <>
                  <FormControl className={classes.formControl}>
                    <TextField id="ragione_sociale_utente" label='Ragione sociale' variant="filled" 
                      value={userDetailsState.ragione_sociale} onChange={(e) => handleUser(e, 'ragione_sociale')}
                      onFocus={()=> _setTouched('ragione_sociale')} autoComplete="name"
                    />
                    {/* {userDetailsState.ragione_sociale === '' && userDetailsTouchedState.ragione_sociale */}
                    {userDetailsState.ragione_sociale === ''
                      && 'Campo obbligatorio'}
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField id="partita_iva_utente" label='P.IVA' variant="filled" 
                      value={userDetailsState.partita_iva} onChange={(e) => handleUser(e, 'partita_iva')}
                      onFocus={()=> _setTouched('partita_iva')} autoComplete="on"
                    />
                    {/* {userDetailsState.partita_iva === '' && userDetailsTouchedState.partita_iva */}
                    {/* {userDetailsState.partita_iva !== '' && userDetailsTouchedState.partita_iva &&
                      userDetailsState.partita_iva.length !== 11 && 'Deve avere 11 cifre'} */}
                    {userDetailsState.partita_iva === ''
                      && 'Campo obbligatorio! '}
                    {userDetailsState.partita_iva !== '' && 
                      userDetailsState.partita_iva.length !== 11 && 'Deve avere 11 cifre'}
                  </FormControl>
                </>
              }
              {userType === 'privato' &&
                <>
                  <FormControl className={classes.formControl}>
                    <TextField id="nome_e_cognome_utente" label='Nome e cognome' variant="filled" 
                      value={userDetailsState.nome_e_cognome} onChange={(e) => handleUser(e, 'nome_e_cognome')} 
                      onFocus={()=> _setTouched('nome_e_cognome')} autoComplete="name"
                      />
                      {/* {userDetailsState.nome_e_cognome === '' && userDetailsTouchedState.nome_e_cognome */}
                      {userDetailsState.nome_e_cognome === ''
                        && 'Campo obbligatorio'}
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField id="codice_fiscale_utente" label='Codice fiscale' variant="filled" 
                      value={userDetailsState.codice_fiscale} onChange={(e) => handleUser(e, 'codice_fiscale')}
                      onFocus={()=> _setTouched('codice_fiscale')} autoComplete="on"
                    />
                    {/* {userDetailsState.codice_fiscale === '' && userDetailsTouchedState.codice_fiscale */}
                    {userDetailsState.codice_fiscale === ''
                        && 'Campo obbligatorio'}
                  </FormControl>
                </>
              }
              <FormControl className={classes.formControl}>
                <TextField id="telefono_utente" label='Telefono' variant="filled" 
                  value={userDetailsState.telefono} onChange={(e) => handleUser(e, 'telefono')}
                  onFocus={()=> _setTouched('telefono')} autoComplete="on"
                />
                {/* {userDetailsState.telefono === '' && userDetailsTouchedState.telefono */}
                {userDetailsState.telefono === ''
                  && 'Campo obbligatorio!'}
                {/* {touched.telefono &&
                  typeof errors.telefono === "string" &&
                  errors.telefono} */}
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField id="email_utente" label='Email' variant="filled" 
                  value={userDetailsState.email} onChange={(e) => handleUser(e, 'email')}
                  onFocus={()=> _setTouched('email')}  autoComplete="email"
                />
                {/* {userDetailsState.email === '' && userDetailsTouchedState.email
                  && 'Campo obbligatorio! '}
                {userDetailsState.email !== '' && userDetailsTouchedState.email &&
                  !validEmail  && 'Mail non valida'} */}
                {userDetailsState.email === ''
                  && 'Campo obbligatorio! '}
                {userDetailsState.email !== '' &&
                  !validEmail  && 'Mail non valida'}
              </FormControl>
              {userType === 'partita_iva' &&
                <>
                  <h2>Per la fatturazione elettronica utilizzo:</h2>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Seleziona la tua categoria di appartenenza</FormLabel>
                    <RadioGroup aria-label="tipo_identificativo" name="tipo_identificativo" value={fatturazioneValue} onChange={handleTipoIdentificativo}>
                      <FormControlLabel value="codice_univoco" control={<Radio />} label="Codice Univoco" />
                      <FormControlLabel value="pec" control={<Radio />} label="Pec" />
                      <FormControlLabel value="forfettario" control={<Radio />} label="Regime forfettario" />
                    </RadioGroup>
                  </FormControl>
                  {userDetails.tipo_identificativo === "codice_univoco" &&
                    <>
                      <FormControl className={classes.formControl}>
                        <TextField id="input_codice_univoco" label='Codice Univoco' variant="filled" 
                          value={userDetailsState.input_codice_univoco} onChange={(e) => handleUser(e, 'input_codice_univoco')}
                          onFocus={()=> _setTouched('input_codice_univoco')}  autoComplete="email"
                        />
                        {userDetails.tipo_identificativo === "pec" && userDetailsState.input_codice_univoco === '' 
                        // && userDetailsTouchedState.input_codice_univoco
                          && 'Campo obbligatorio! '}
                      </FormControl>
                    </>
                  }
                  {userDetails.tipo_identificativo === "pec" &&
                    <>
                      <FormControl className={classes.formControl}>
                        <TextField id="input_pec" label='Pec' variant="filled" 
                          value={userDetailsState.input_pec} onChange={(e) => handleUser(e, 'input_pec')}
                          onFocus={()=> _setTouched('input_pec')}  autoComplete="email"
                        />
                        {userDetails.tipo_identificativo === "pec" && userDetailsState.input_pec === '' 
                        // && userDetailsTouchedState.input_pec
                          && 'Campo obbligatorio! '}
                        {/* {userDetails.tipo_identificativo === "pec" && userDetailsState.input_pec !== '' && userDetailsTouchedState.input_pec &&
                          !validEmail  && 'Mail non valida'} */}
                      </FormControl>
                    </>
                  }
                </>
              }
            </>
          }
        </div>
        <div style={{marginTop: "20px", textAlign: "center"}}>
          <NavigationContext.Consumer>
            {value => {
              const _navigation = () => {
                _setUserDetails();
                value.handleNavigation('Settori', 'settori', '', '')
              }
              return (
                <Button variant='contained' color='secondary' 
                  onClick={_navigation} disabled={isDisabled}>
                  Next
                </Button>
              )
            }}
          </NavigationContext.Consumer>
        </div>
      </>
    </Fade>
  )
}

export {TipoUtente as default}
