const elencoNavigation = {
  current: "Start",
  currentId: "Start",
  close: null,
  closeId: null,
  temp: null,
  tempId: null
};

export { elencoNavigation as default }
