export const SET_USERTYPE = 'SET_USERTYPE';
export const SET_TIPOIDENTIFICATIVO = 'SET_TIPOIDENTIFICATIVO';
export const SET_USERDETAILS = 'SET_USERDETAILS';
export const SET_USERTOUCHEDFIELDS = 'SET_USERTOUCHEDFIELDS';
export const SET_FILTER = 'SET_FILTER';
export const SET_DOCUMENT = 'SET_DOCUMENT';
//ARTICLES
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_SELECTED_ARTICLE = 'SET_SELECTED_ARTICLE';
export const EDIT_ARTICLE = 'EDIT_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ALL_ARTICLES = 'DELETE_ALL_ARTICLES';
export const ADD_ARTICLE = 'ADD_ARTICLE';