import React, {useEffect} from 'react';
import Fade from '@material-ui/core/Fade';

const NumeroVerde = (props) => {
  useEffect(() => {
    props.close("Settori", "settori");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);

  return (
    <Fade in={props.checked}>
      <div>
        <h2>Ecco come puoi contattarci:</h2>
        <p>Chiama il nostro numero verde:</p>
        <ul>
          <li>
            <strong> 800 662921</strong>
          </li>
        </ul>
        <p>Scrivici un'e-mail:</p>
        <ul>
          <li>
            <strong> servizioclienti@muster-dikson.com</strong>
          </li>
        </ul>
      </div>
    </Fade>
  )
}
export { NumeroVerde as default }
