const elencoSettori = {
  settori: [
    {
      id: "par",
      nome: "Parrucchiere",
      description: "",
      categorie: [
        {
          id: "parpro",
          nome: "Prodotti",
          description: "Shampoo, tinte, lozioni, ecc."
        },
        {
          id: "pararr",
          nome: "Arredamento",
          description: "",
        },
        {
          id: "parele",
          nome: "Elettrici",
          description: "Caschi, phon, piastre, tagliacapelli, ecc.",
        },
        {
          id: "parmin",
          nome: "Minuterie e piccole attrezzature non elettriche",
          description: "Spazzole, carrelli, forbici, ecc.",
        }
      ]
    },
    {
      id: "est",
      nome: "Estetica",
      description: "",
      categorie: [
        {
          id: "estpro",
          nome: "Prodotti Cosmetici",
          description: ""
        },
        {
          id: "estnai",
          nome: "Nails",
          description: ""
        },
        {
          id: "estarr",
          nome: "Arredamento",
          description: ""
        },
        {
          id: "estatt",
          nome: "Attrezzature",
          description: "Lettini, carrelli, poltrone pedicure, ecc."
        },
        {
          id: "estapp",
          nome: "Apparecchi",
          description: "Pressoterapia, laser, autoclave, ecc."
        },
        {
          id: "estele",
          nome: "Elettrici",
          description: "Vaporizzatori, scaldacera, fornetti unghie, ecc."
        },
        {
          id: "estmin",
          nome: "Minuterie e piccole attrezzature non elettriche",
          description: "Lime, tronchesi, forbicine, ecc.",
        }
      ]
    }
  ]
};

export { elencoSettori as default }
