import React, {useState, useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {
	FormikWizardProvider,
	Wizard,
	StepsList,
	Step,
	ButtonsList,
	PreviousButton,
	NextButton,
	SubmitButton
} from  "formik-wizard-form";
import Hidden from '@material-ui/core/Hidden';
import { DisplayFormikState } from './model/helper';
import SegnalazioneProblema from './steps/SegnalazioneProblema';
import SegnalazioneProblema2 from './steps/SegnalazioneProblema2';
import RiepilogoProblema from './steps/RiepilogoProblema';
import FormSubmitted from './FormSubmitted';
import './form.css';
import { getUserType, getArticlesList } from '../../redux/selectors';
import {setArticlesApi, addArticleApi, deleteAllArticles} from '../../redux/actions/actions';

function datiValidations({ errors }) {
  return !errors.noleggio_oppure_Acquisto 
		&& !errors.numero_contratto && !errors.societa_noleggio
		&& !errors.rivenditore_presente	&& !errors.ragione_sociale_rivenditore
		&& !errors.citta_rivenditore && !errors.piva_rivenditore 
		// && !errors.tipo_identificativo 
		// && !errors.input_pec && !errors.input_codice_univoco		
}

function articoloValidations({ errors }) {
	return !errors.codice_articolo
		&& !errors.matricola_o_lotto_articolo
		&& !errors.articolo_in_Garanzia
		&& !errors.descrizione_guasto && !errors.quando_si_verifica_il_problema
		&& !errors.descrizione_casistica 
		&& !errors.add_article
}

const FormSegnalazione = (props) => {
	const values = props.values;
	// const errors = props.errors;
	const setFieldValue = props.setFieldValue;
	const [nextButtonText, setNextButtonText] = useState('Avanti');
	const [isHidden, setIsHidden] = useState(false);
	
	useEffect(() => {
		props.setFieldValue("mailAssistenza", props.mailAssistenza);
		props.setFieldValue("tipologia_richiesta", props.tipologiaId);
	}, []);

	const dispatch = useDispatch();
	const articles = useSelector(getArticlesList);
  const [stepId, setStepId] = useState(1);
  const [addArticleButton, setAddArticleButton] = useState(false);
  const [isTextHidden, setIsTextHidden] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

  const addNewArticle = (articleId) => {
    setAddArticleButton(false);
    let newArticle = {
				id: articleId,
        codice_articolo: values.codice_articolo,
        nome_articolo: values.nome_articolo,
        descrizione_articolo: values.descrizione_articolo,
        matricola_o_lotto_articolo: values.matricola_o_lotto_articolo,
        articolo_in_Garanzia: values.articolo_in_Garanzia,
        uploadedFileName: values.uploadedFileName,
        uploadedGaranzia: values.uploadedGaranzia,
        descrizione_guasto: values.descrizione_guasto,
        numero_pezzi: values.numero_pezzi,
        quando_si_verifica_il_problema: values.quando_si_verifica_il_problema,
        descrizione_casistica: values.descrizione_casistica,
        descrizione_tentativi: values.descrizione_tentativi
    }
    
    setFieldValue('codice_articolo', '');
    setFieldValue('nome_articolo', '');
    setFieldValue('descrizione_articolo', '');
    setFieldValue('matricola_o_lotto_articolo', '');
    setFieldValue('articolo_in_Garanzia', '');
    setFieldValue('uploadedFileName', []);
    setFieldValue('descrizione_guasto', '');
    setFieldValue('numero_pezzi', '');
    setFieldValue('quando_si_verifica_il_problema', '');
    setFieldValue('descrizione_casistica', '');
    setFieldValue('descrizione_tentativi', '');
    setFieldValue('add_article', 'ok');
    setFieldValue('uploadedGaranzia', []);
    setFieldValue('uploadedDifetto', []);
		setIsHidden(false);
		setIsTextHidden(false);
		dispatch(addArticleApi(newArticle));
  }
	
  useEffect(()=> {
		(values.codice_articolo == '' || !values.matricola_o_lotto_articolo || !values.quando_si_verifica_il_problema || !values.descrizione_guasto || !values.articolo_in_Garanzia) ? setAddArticleButton(false) : setAddArticleButton(true);
  }, [values.codice_articolo, values.matricola_o_lotto_articolo, values.quando_si_verifica_il_problema, values.descrizione_guasto, values.articolo_in_Garanzia]);
	
	useEffect(()=> {
		dispatch(deleteAllArticles({}));
  }, []);
	
	return(
		<React.Fragment>
		{ !props.isSubmitting ? (
			<React.Fragment>
				<p>
					La scheda può essere compilata per
					<strong> molteplici articoli</strong>.
				</p>
				<FormikWizardProvider {...props}>
				{({ getValidators, ...otherProps }) => {return(
					<Wizard {...otherProps}>
						<StepsList
							validators={getValidators(
							[datiValidations, articoloValidations]
							)}
						>
							<Step
								component={SegnalazioneProblema} setNextButtonText={setNextButtonText}
								checked={props.checked} title="Dati" setIsHidden={setIsHidden} setStepId={setStepId}
							/>
							<Step
								component={SegnalazioneProblema2} setNextButtonText={setNextButtonText}
								checked={props.checked} title="Articolo" addNewArticle={addNewArticle}
								addArticleButton={addArticleButton} setIsHidden={setIsHidden} 
								isTextHidden={isTextHidden} setIsTextHidden={setIsTextHidden} setStepId={setStepId}
							/>
							<Step
								component={RiepilogoProblema}
								checked={props.checked} title="Controlla" setStepId={setStepId}
								isChecked={isChecked} setIsChecked={setIsChecked}
							/>
						</StepsList>
						<ButtonsList>
							<PreviousButton label="Indietro" />
							{stepId === 2 && (articles.length === 0 || isHidden) ? 
								<Hidden xsUp>
									<NextButton label={nextButtonText} />
								</Hidden>
								: <NextButton label={nextButtonText} />
							}
							{isChecked ?
								<SubmitButton label="INVIA" /> :
								<Hidden xsUp>
									<SubmitButton label="INVIA" />
								</Hidden>
							}
						</ButtonsList>
					</Wizard>
				)}}
				</FormikWizardProvider>
				{/* <DisplayFormikState {...props} /> */}
			</React.Fragment>
		) : (<FormSubmitted props={props}/>)}
		{/* {isHidden ? 'hidden' : 'shown'} {articles.length} {stepId} */}
	  </React.Fragment>
	)
};

export { FormSegnalazione as default};
