import React, {useEffect} from 'react';
import Fade from '@material-ui/core/Fade';

const BeautyTrainer = (props) => {
  useEffect(() => {
    props.close("Settori", "settori");
    return function closeUpdate() {
      props.close(null, null);
    }
  }, []);

  return (
    <Fade in={props.checked}>
      <div>
        <h2>Ecco come puoi contattarci:</h2>
        <p>Chiama la nostra Beauty Trainer:</p>
        <ul>
          <li>
            <strong> 0331 525412</strong><br/>
          </li>
          <li>
            <strong> 346 5361076</strong>
          </li>
        </ul>
        <p>Scrivici un'e-mail:</p>
        <ul>
          <li>
            <strong> michela.cannavale@muster-dikson.com</strong>
          </li>
        </ul>
      </div>
    </Fade>
  )
}
export { BeautyTrainer as default }
