import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import { Field } from "formik";
import Modal from '@material-ui/core/Modal';
import Upload from '../../upload/Upload';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from "react-redux";
import { getArticlesList, getArticleDetails } from '../../../redux/selectors';
import {deleteArticle, setSelectedArticle, editArticle} from '../../../redux/actions/actions';

import '../../form.css';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 40
  },
  paper: {
    position: 'relative',
    backgroundColor: "#393939",
    color: 'white',
    flexGrow: 1,
    padding: "0 10px 20px 10px"
  },
  undoButton: {
    color: 'black',
    margin: '8px',
    padding: '20px 30px 20px 30px',
    border: '1px solid #b2b2b2',
    backgroundColor: '#ffffff'
  },
  cancelButton: {
    backgroundColor: '#13518b',
    color: 'white',
    margin: theme.spacing(1),
    padding: '20px 30px 20px 30px',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  },
  deleteButton: {
    backgroundColor: '#f50057',
    color: 'white',
    margin: theme.spacing(1),
    padding: '20px 30px 20px 30px',
    '&:hover': {
      backgroundColor: '#cf024b'
    }
  },
  formControl: {
    margin: theme.spacing(2),
  },
  fullWidth: {
    margin: theme.spacing(2),
    width: 'calc(100% - 32px)'
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  paragraph: {
    marginTop: '50px'
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  insertArticleButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '30px 10px'
  },
  newButton: {
    marginBottom: '0px',
    margin: theme.spacing(2),
  },
  topLeftCorner: {
    color: 'white',
    display: 'inline-block'
  },
  topRightCorner: {
    display: 'inline-block',
    // backgroundColor: 'white'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    height: '100%'
  },
  modalPaper: {
    overflow: 'scroll',
    maxHeight: '95vh',
    maxWidth: '500px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const CustomTextField = ({field, label, placeholder, multiline}) => {
  return (
  <TextField {...field} label={label} multiline={multiline}
    variant="filled" placeholder={placeholder} />
  )
}

const RadioGaranzia = ({
  field: {name, value, onChange, onBlur},
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio />}
    checked={id === value} onChange={onChange} onBlur={onBlur}
  />
)

const RadioGaranziaGroup = (
  {value, error, touched, id, label, className, children}
) => {
  const classes = useStyles();
  
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup>
        {children}
      </RadioGroup>
    </FormControl>
  )
}

const RadioFoto = ({
  field: {name, value, onChange, onBlur},
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio />}
    checked={id === value} onChange={onChange} onBlur={onBlur}
  />
)

const RadioFotoGroup = (
  {value, error, touched, id, label, className, children}
) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup>
        {children}
      </RadioGroup>
    </FormControl>
  )
}

const RadioTrasporto = ({
  field: {name, value, onChange, onBlur},
  id, label, className, ...props
}) => (
  <FormControlLabel
    value={id} name={name} id={id} label={label} control={<Radio />}
    checked={id === value} onChange={onChange} onBlur={onBlur}
  />
)

const RadioTrasportoGroup = (
  {value, error, touched, id, label, className, children}
) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup>
        {children}
      </RadioGroup>
    </FormControl>
  )
}

const RMA2 = ({touched, errors, values, setFieldValue, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const articles = useSelector(getArticlesList);
  const selectedArticle = useSelector(getArticleDetails);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [articleId, setArticleId] = useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const handleSnackClick = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = (event, reason) => {
    setSnackOpen(false);
  };
  const [showNewArticleFields, setShowNewArticleFields] = useState(false);
  const [selectedArticleState, setSelectedArticleState] = useState({
    id: '',
    codice_articolo:'',
    nome_articolo:'',
    descrizione_articolo:'',
    matricola_o_lotto_articolo:'',
    articolo_in_Garanzia:'',
    uploadedFileName: [],
    uploadedGaranzia: '',
    uploadedDifetto: '',
    allega_foto: '',
    descrizione_difetto:'',
    numero_pezzi:'',
  });

  useEffect(()=> {
    window.scrollTo(0, 0)
    props.setNextButtonText('Concludi');
    articles.length === 0 && props.setIsHidden(true);
    props.setIsTextHidden(true);
    props.setStepId(2);
  }, []);
  
  useEffect(() => {
    selectedArticle.length > 0 &&
    setSelectedArticleState({
      id: selectedArticle[0].id,
      codice_articolo: selectedArticle[0].codice_articolo,
      nome_articolo: selectedArticle[0].nome_articolo,
      descrizione_articolo: selectedArticle[0].descrizione_articolo,
      matricola_o_lotto_articolo: selectedArticle[0].matricola_o_lotto_articolo,
      articolo_in_Garanzia: selectedArticle[0].articolo_in_Garanzia,
      uploadedFileName: selectedArticle[0].uploadedFileName,
      uploadedGaranzia: selectedArticle[0].uploadedGaranzia,
      uploadedDifetto: selectedArticle[0].uploadedDifetto,
      allega_foto: selectedArticle[0].allega_foto,
      descrizione_difetto: selectedArticle[0].descrizione_difetto,
      numero_pezzi: selectedArticle[0].numero_pezzi,
    })
  },[selectedArticle])
  
  const _newArticleFields = () => {
    props.setIsHidden(true);
    setShowNewArticleFields(true);
  }
  
  const _addNewArticle = () => {
    setShowNewArticleFields(false);
    window.scrollTo(0, 0);
    props.addNewArticle(articleId);
    setArticleId(articleId + 1);
  }

  const _editModalOpen = (articleId) => {
    dispatch(setSelectedArticle(articleId));
    setEditModalOpen(true);
  }

  const _editArticle = (articleId) => {
    dispatch(editArticle([{
      id: articleId,
      codice_articolo: selectedArticleState.codice_articolo,
      nome_articolo: selectedArticleState.nome_articolo,
      descrizione_articolo: selectedArticleState.descrizione_articolo,
      matricola_o_lotto_articolo: selectedArticleState.matricola_o_lotto_articolo,
      articolo_in_Garanzia: selectedArticleState.articolo_in_Garanzia,
      uploadedFileName: selectedArticleState.uploadedFileName,
      uploadedGaranzia: selectedArticleState.uploadedGaranzia,
      uploadedDifetto: selectedArticleState.uploadedDifetto,
      allega_foto: selectedArticleState.allega_foto,
      descrizione_difetto: selectedArticleState.descrizione_difetto,
      numero_pezzi: selectedArticleState.numero_pezzi,
    }]));
    setEditModalOpen(false);
    setSnackOpen(true);
  }

  const _deleteModalOpen = (articleId) => {
    dispatch(setSelectedArticle(articleId));
    setDeleteModalOpen(true);
  }

  const _deleteArticle = (articleId) => {
    dispatch(deleteArticle(articleId));
    setDeleteModalOpen(false);
  }
  
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleSelectedArticle = (event, type) => {
    let value = event.target.value
    setSelectedArticleState({
      ...selectedArticleState,
      [type]: value
    });
  }
  
  const ArticleDetail = (props) => {
    return (
      <Paper elevation={2} className={classes.paper}>
        <div className={classes.iconButtonsContainer}>
          <IconButton component="span"
            className={classes.topLeftCorner}
            onClick={ () => _editModalOpen(props.article.id)}>
            <EditIcon></EditIcon>
          </IconButton>
          <IconButton color="secondary" component="span"
            className={classes.topRightCorner}
            onClick={() => _deleteModalOpen(props.article.id)}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </div>
        <Typography variant="h6">
          Art. {props.article.codice_articolo}
        </Typography>
        {props.article.matricola_o_lotto_articolo &&
          <Typography variant="body2">
            Lotto/matricola: {props.article.matricola_o_lotto_articolo}
          </Typography>
        }
      </Paper>
    )
  }
  
  return (
    <Fade in={props.checked}>
    <>
      <div>
        {articles.length > 0 &&
          <>
            <h3>Articoli inseriti:</h3>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={2}>
                  {articles.map((article, index) => (
                    <Grid key={index} item xs={6} md={6}>
                      <ArticleDetail article={article} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        }

        {articles.length > 0 && !showNewArticleFields &&
          <div className={classes.buttonContainer}>
            <p><strong>Vuoi inserire un altro articolo?</strong><br/>Premi nuovamente il pulsante "aggiungi articolo", altrimenti premi "concludi".</p>
          </div>
        }

        {/* {!showNewArticleFields &&
          <div className={classes.insertArticleButtonContainer}>
            <Button color="primary" variant="contained" onClick={() => setShowNewArticleFields(true)}>
              Aggiungi articolo
            </Button>
          </div>
        } */}
        {!showNewArticleFields && values.noleggio_oppure_Acquisto === 'noleggio' &&
          <div className={classes.insertArticleButtonContainer}>
            {articles && articles.length === 0 ?
              <Button color="primary" variant="contained" onClick={_newArticleFields}>
                Inserisci articolo
              </Button> : <></>
            }
          </div>
        }
        {!showNewArticleFields && values.noleggio_oppure_Acquisto === 'acquisto' &&
          <div className={classes.insertArticleButtonContainer}>
            {articles && articles.length === 0 ?
              <Button color="primary" variant="contained" onClick={_newArticleFields}>
                Inserisci articolo
              </Button> : 
              <Button color="primary" variant="contained" onClick={_newArticleFields}>
                Aggiungi articolo
              </Button>
            }
          </div>
        }
        {!showNewArticleFields && values.noleggio_oppure_Acquisto === '' &&
          <div className={classes.insertArticleButtonContainer}>
            {articles && articles.length === 0 ?
              <Button color="primary" variant="contained" onClick={_newArticleFields}>
                Inserisci articolo
              </Button> : 
              <Button color="primary" variant="contained" onClick={_newArticleFields}>
                Aggiungi articolo
              </Button>
            }
          </div>
        }

        {showNewArticleFields && <>
        <h2>Dettagli articolo</h2>  
        <FormControl className={classes.formControl}>
          <Field name="codice_articolo" label="Codice Articolo"
            component={CustomTextField}
          />
          {touched.codice_articolo &&
            values.codice_articolo === "" &&
            "Campo obbligatorio"}
        </FormControl>
        <FormControl className={classes.formControl}>
          <Field name="nome_articolo" label="Nome Articolo"
            component={CustomTextField}
          />
        </FormControl>
        <FormControl className={classes.fullWidth}>
          <Field name="descrizione_articolo"
            label="Descrizione dell'articolo"
            multiline
            placeholder="Es. Phon Safari / Poltrona Brenda"
            component={CustomTextField}
          />
        </FormControl>
        <FormControl className={classes.fullWidth}>
        <Field name="matricola_o_lotto_articolo"
          label='N° matricola / serial number o lotto'
          placeholder='se presente - altrimenti scrivere "NO"'
          component={CustomTextField}
        />
        {touched.matricola_o_lotto_articolo &&
          values.matricola_o_lotto_articolo === "" &&
          "Campo obbligatorio"}
        </FormControl>

        {touched.codice_articolo && values.codice_articolo !== "" && 
          <>
            <h2 className={classes.paragraph}>Dettagli garanzia</h2> 
            <RadioGaranziaGroup
              label="L'articolo è in garanzia?"
              id="articoloInGaranzia" value={values.radioGroup}
              error={errors.radioGroup} touched={touched.radioGroup}
            >
              <Field component={RadioGaranzia} name="articolo_in_Garanzia"
                id="garanziaSi" label="Articolo in garanzia"
              />
              <Field component={RadioGaranzia} name="articolo_in_Garanzia"
                id="garanziaNo" label="Articolo non in garanzia"
              />
            </RadioGaranziaGroup>
          </>
        }

        {values.articolo_in_Garanzia === "garanziaSi" &&
          values.noleggio_oppure_Acquisto !== 'noleggio' &&
          <React.Fragment>
            <h2>Allegare copia dello scontrino fiscale o della fattura di acquisto tra distributore e cliente utilizzatore</h2>
            <Upload setValue={setFieldValue} blobType="uploadedGaranzia"
              uploadedFiles={values.uploadedFileName} artCod={values.codice_articolo}
              uploadedFileBlob={values.uploadedGaranzia}
            />
            <p><strong>N.B.</strong> Se non viene allegato lo scontrino fiscale
              o la fattura di acquisto, l’articolo verrà automaticamente
              considerato non in garanzia.
            </p>
          </React.Fragment>
        }

        <h2 className={classes.paragraph}>Dettagli problema</h2> 
        <FormControl className={classes.fullWidth}>
          <h4>Non scrivere soltanto "non funziona": descrivi accuratamente il problema.</h4>
          <Field name="descrizione_difetto"
            label="Descrizione difetto."
            multiline
            placeholder='Descrizione dettagliata (evitare "Non funziona")'
            component={CustomTextField}
          />
          {touched.descrizione_difetto &&
            values.descrizione_difetto === "" &&
            "Campo obbligatorio"}
        </FormControl>
        <FormControl className={classes.fullWidth}>
            <Field name="numero_pezzi"
              label="Numero pezzi"
              multiline
              placeholder="Specificare n. pezzi dell'articolo che si vorrebbe rendere"
              component={CustomTextField}
            />
            {touched.numero_pezzi &&
              values.numero_pezzi === "" &&
              "Campo obbligatorio"}
        </FormControl>
        <RadioFotoGroup
          label="Invio foto e/o video del difetto"
          id="aggiungiFotoDifetto" value={values.radioGroup}
          error={errors.radioGroup} touched={touched.radioGroup}
        >
          <Field component={RadioFoto} name="allega_foto"
            id="fotoSi" label="Sì"
          />
          <Field component={RadioFoto} name="allega_foto"
            id="fotoNo" label="No"
          />
        </RadioFotoGroup>
        {values.allega_foto === "fotoSi" &&
          <React.Fragment>
            <h2>Carica Foto / Video</h2>
            <Upload setValue={setFieldValue} blobType="uploadedDifetto"
              uploadedFiles={values.uploadedFileName} artCod={values.codice_articolo}
              uploadedFileBlob={values.uploadedDifetto}
              />
          </React.Fragment>
        }

        <div className={classes.insertArticleButtonContainer}>
          <Button variant="contained" color="primary" disabled={!props.addArticleButton}
            className={classes.newButton} onClick={_addNewArticle}>
            Salva articolo
          </Button>
        </div>
      </>}
        <br/>
        <br/>
        <br/>
        <h2>Dopo l'autorizzazione della presente scheda</h2>
        <RadioTrasportoGroup
          label="Trasporto dell'articolo/i in azienda a cura di:"
          id="modalitaTrasporto" value={values.radioGroup}
          error={errors.radioGroup} touched={touched.radioGroup}
        >
          <Field component={RadioFoto} name="trasporto_a_cura_di"
            id="Utilizzatore" label="Dell'utilizzatore"
          />
          <Field component={RadioFoto} name="trasporto_a_cura_di"
            id="Rivenditore" label="Del rivenditore"
          />
          <Field component={RadioFoto} name="trasporto_a_cura_di"
            id="Muster" label="Di Müster & Dikson"
          />
        </RadioTrasportoGroup>
        {values.trasporto_a_cura_di === "Muster" &&
          <React.Fragment>
            {/* {values.dati_utilizzatore === "societa" || values.dati_utilizzatore === "distributore"  || values.dati_utilizzatore === "privato" &&  */}
              <React.Fragment>
                <h2>Allegare copia ddt (bolla di reso)</h2>
                <Upload setValue={setFieldValue} blobType="uploadedDdt"
                  uploadedFiles={values.uploadedFileName}
                  uploadedFileBlob={values.uploadedDdt}
                />
              </React.Fragment>
            {/* } */}

            <h3>Informazioni sul ritiro</h3>  
            <FormControl className={classes.fullWidth}>
              <Field component={CustomTextField} multiline
                name="indirizzo_ritiro" label="Indirizzo"
                placeholder="Inserire via e num. civico"
              />
              {touched.indirizzo_ritiro &&
                typeof errors.indirizzo_ritiro === "string" &&
                errors.indirizzo_ritiro}
            </FormControl>
            <FormControl className={classes.fullWidth}>
              <Field component={CustomTextField} multiline
                name="citta_ritiro" label="Città"
                placeholder="Indicare la città"
              />
              {touched.citta_ritiro &&
                typeof errors.citta_ritiro === "string" &&
                errors.citta_ritiro}
            </FormControl>
            <FormControl className={classes.fullWidth}>
              <Field component={CustomTextField} multiline
                name="provincia_ritiro" label="Provincia"
                placeholder="Indicare la provincia"
              />
              {touched.provincia_ritiro &&
                typeof errors.provincia_ritiro === "string" &&
                errors.provincia_ritiro}
            </FormControl>
            <FormControl className={classes.fullWidth}>
              <Field component={CustomTextField} multiline
                name="cap_ritiro" label="Cap"
                placeholder="Indicare il cap"
              />
              {touched.cap_ritiro &&
                typeof errors.cap_ritiro === "string" &&
                errors.cap_ritiro}
            </FormControl>
            <FormControl className={classes.fullWidth}>
              <Field component={CustomTextField} multiline
                name="telefono_ritiro" label="Telefono"
                placeholder="Indicare numero di telefono di riferimento per il ritiro"
              />
              {touched.telefono_ritiro &&
                typeof errors.telefono_ritiro === "string" &&
                errors.telefono_ritiro}
            </FormControl>
          </React.Fragment>
        }

      </div>

      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={deleteModalOpen}>
            <div className={classes.modalPaper}>
              {selectedArticle && selectedArticle.length > 0 &&
                <>
                  <h2 style={{textAlign: "center"}} id="transition-modal-title">Eliminazione articolo</h2>
                  <p id="transition-modal-description">Vuoi eliminare questo articolo?</p>
                  <p>Codice: {selectedArticle[0].codice_articolo}</p>
                  <div className={classes.buttonContainer}>
                    <Button color="default" className={classes.cancelButton} onClick={handleDeleteModalClose}>Annulla</Button>
                    <Button color="default" className={classes.deleteButton} onClick={() => _deleteArticle(selectedArticle[0].id)}>Elimina</Button>
                  </div>
                </>
              }
            </div>
          </Fade>
        </Modal>

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalEdit}
        open={editModalOpen}
        onClose={handleEditModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModalOpen}>
          <div className={classes.modalPaper}>
            {selectedArticle && selectedArticle.length > 0 &&
              <>
                <h2 style={{textAlign: "center"}} id="transition-modal-title">Modifica art.{selectedArticleState.codice_articolo}</h2>

          <h2>Dettagli articolo</h2>  
          <FormControl className={classes.formControl}>
            <TextField id="edit_codice_articolo" label='Codice Articolo' variant="filled" 
              value={selectedArticleState.codice_articolo} onChange={(e) => handleSelectedArticle(e, 'codice_articolo')}
            />
            {selectedArticleState.codice_articolo === "" &&
              "Campo obbligatorio"}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="edit_nome_articolo" label='Nome Articolo' variant="filled" 
              value={selectedArticleState.nome_articolo} onChange={(e) => handleSelectedArticle(e, 'nome_articolo')}
            />
          </FormControl>
          <FormControl className={classes.fullWidth}>
            <TextField id="edit_descrizione_articolo" label="Descrizione dell'articolo" variant="filled" 
              value={selectedArticleState.descrizione_articolo} onChange={(e) => handleSelectedArticle(e, 'descrizione_articolo')}
            />
          </FormControl>
          <FormControl className={classes.fullWidth}>
            <TextField id="edit_matricola_o_lotto_articolo"  label='N° matricola / serial number o lotto'
              placeholder='se presente - altrimenti scrivere "NO"' variant="filled" 
              value={selectedArticleState.matricola_o_lotto_articolo} onChange={(e) => handleSelectedArticle(e, 'matricola_o_lotto_articolo')}
            />
            {selectedArticleState.matricola_o_lotto_articolo === "" &&
              "Campo obbligatorio"}
          </FormControl>
          
          <h2 className={classes.paragraph}>Dettagli problema</h2> 
          <FormControl className={classes.fullWidth}>
            <h4>Non scrivere soltanto "non funziona": descrivi accuratamente il problema.</h4>
            <TextField id="edit_descrizione_difetto"label="Descrizione difetto." multiline
              placeholder='Descrizione dettagliata (evitare "Non funziona")' variant="filled" 
              value={selectedArticleState.descrizione_difetto} onChange={(e) => handleSelectedArticle(e, 'descrizione_difetto')}
            />
            {selectedArticleState.descrizione_difetto === "" &&
              "Campo obbligatorio"}
          </FormControl>
          <FormControl className={classes.fullWidth}>
            <TextField id="edit_numero_pezzi" label="Numero pezzi"
              multiline placeholder="Specificare n. pezzi su cui hai riscontrato questo problema" variant="filled" 
              value={selectedArticleState.numero_pezzi} onChange={(e) => handleSelectedArticle(e, 'numero_pezzi')}
            />
            {selectedArticleState.numero_pezzi === "" &&
              "Campo obbligatorio"}
          </FormControl>
          
                <div className={classes.buttonContainer}>
                  <Button color="default" variant="outlined" className={classes.undoButton} onClick={handleEditModalClose}>Annulla</Button>
                  <Button color="default" className={classes.cancelButton} onClick={() => _editArticle(selectedArticle[0].id)}>Modifica</Button>
                </div>
              </>
            }
          </div>
        </Fade>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        contentProps={classes.snackbar}
        open={snackOpen}
        autoHideDuration={1500}
        onClose={handleSnackClose}
      >
        <SnackbarContent style={{
            backgroundColor:'teal',
          }}
          message={<span id="client-snackbar">Articolo modificato!</span>}
        />
      </Snackbar>
      </>
    </Fade>
  )
};
export { RMA2 as default }
