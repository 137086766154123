import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector } from "react-redux";
import { getArticlesList, getUserDetails } from '../../../redux/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 40
  },
  article: {
    backgroundColor: "#f3f3f3",
    flexGrow: 1,
    padding: "10px 20px"
  },
  formControl: {
    margin: theme.spacing(2),
  },
  fullWidth: {
    margin: theme.spacing(2),
    width: 'calc(100% - 32px)'
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  myButton: {
    backgroundColor: '#13518b',
    color: 'white',
    margin: theme.spacing(1),
    padding: '20px 30px 20px 30px',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  },
  printButtonContainer: {
    textAlign: 'left'
  },
  printButton: {
    backgroundColor: '#13518b',
    color: 'white',
    margin: theme.spacing(1),
    padding: '0 20px',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  }
}));

const RenderThumb = (props) => {
  const [thumb, setThumb] = React.useState(undefined);

  let reader = new FileReader();
  reader.onloadend = () => {
    setThumb(reader.result);
  }
  reader.readAsDataURL(props.file);
  return (
    <React.Fragment>
      {thumb != undefined ?
        <React.Fragment>
          { thumb.includes("image") ?
            <img src={thumb} width="100px" 
          /> : <PictureAsPdfIcon 
            style={{
                width: "100px",
                height: "100px",
                color: "#c9c9c9"
              }}
            />
          }
        </React.Fragment> : <span></span>
      }
    </React.Fragment>
  )
}

const RiepilogoProblema = ({touched, errors, values, ...props}) => {
  const classes = useStyles();
  const articles = useSelector(getArticlesList);
  const userDetails = useSelector(getUserDetails);
  const stampa = () => window.print();
  
  const _setIsChecked = () => {
    props.setIsChecked(!props.isChecked);
  }
  
  const [open, setOpen] = React.useState(true);
  // const handleOpen = () => {
  //   setOpen(true);
  // }
  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setStepId(3);
  }, [])

  const ArticleDetail = (props) => {
    return (
      <Paper elevation={2} className={classes.article}>
        {/* <Typography variant="h5" component="h2">
            {props.article.articolo} */}
          {props.article.codice_articolo !== "" &&
            <>
              <span>
                Codice articolo: <strong>{props.article.codice_articolo}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.nome_articolo !== "" &&
            <>
              <span>
                Nome articolo: <strong>{props.article.nome_articolo}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.descrizione_articolo !== "" &&
            <>
              <span>
               Descrizione articolo: <strong>{props.article.descrizione_articolo}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.matricola_o_lotto_articolo !== "" &&
            <>
              <span>
                Matricola o lotto articolo: <strong>{props.article.matricola_o_lotto_articolo}</strong>
              </span>
              <br/>
            </>
          }

          {props.article.descrizione_difetto !== "" &&
            <>
              <span>
                Descrizione difetto: <strong>{props.article.descrizione_difetto}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.numero_pezzi !== "" &&
            <>
              <span>
                Numero pezzi: <strong>{props.article.numero_pezzi}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.articolo_in_Garanzia !== "" &&
            <>
              <span>
                L'articolo è in garanzia? <strong>{props.article.articolo_in_Garanzia === 'garanziaSi' ? 'Sì' : 'No'}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.uploadedGaranzia.length > 0 &&
            <>
              <span> Foto garanzia:<br />
                {props.article.uploadedGaranzia.map(file => {
                  return(
                    <RenderThumb key={file.s3Id} file={file} />
                  )
                })}
              </span>
              <br/>
            </>
          }
          {props.article.allega_foto !== "" &&
            <>
              <span>
                Sono presenti altre foto? <strong>{props.article.allega_foto === 'fotoSi' ? 'Sì' : 'No'}</strong>
              </span>
              <br/>
            </>
          }
          {props.article.uploadedDifetto.length > 0 &&
            <>
              <span> Foto difetto:<br />
                {props.article.uploadedDifetto.map(file => {
                  return(
                    <RenderThumb key={file.s3Id} file={file} />
                  )
                })}
              </span>
              <br/>
            </>
          }
        {/* </Typography> */}
      </Paper>
    )
  }
  
  return (
  <Fade in={props.checked}>
    <>
      <div>
        <h3>I tuoi dati:</h3>
        {userDetails.type !== "" && <>
          <span>
            Tipologia cliente: <strong>{userDetails.type}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type !== "privato" && userDetails.ragione_sociale !== "" && <>
          <span>
            Ragione sociale: <strong>{userDetails.ragione_sociale}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type === "privato" && userDetails.nome_e_cognome !== "" && <>
          <span>
            Nome e cognome: <strong>{userDetails.nome_e_cognome}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type !== "privato" && userDetails.partita_iva !== "" && <>
          <span>
            Partita IVA: <strong>{userDetails.partita_iva}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type === "privato" && userDetails.codice_fiscale !== "" && <>
          <span>
            Codice fiscale: <strong>{userDetails.codice_fiscale}</strong>
          </span>
          <br/>
        </>}
        {userDetails.telefono !== "" && <>
          <span>
            Telefono: <strong>{userDetails.telefono}</strong>
          </span>
          <br/>
        </>}
        {userDetails.email !== "" && <>
          <span>
            Email: <strong>{userDetails.email}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type === 'partita_iva' && userDetails.tipo_identificativo !== "" && <>
          <span>
            Tipo Identificativo: <strong>{userDetails.tipo_identificativo}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type === 'partita_iva' && userDetails.tipo_identificativo === 'codice_univoco' && userDetails.input_codice_univoco !== "" && <>
          <span>
            Codice univoco: <strong>{userDetails.input_codice_univoco}</strong>
          </span>
          <br/>
        </>}
        {userDetails.type === 'partita_iva' && userDetails.tipo_identificativo === 'pec' && userDetails.input_pec !== "" && <>
          <span>
            PEC: <strong>{userDetails.input_pec}</strong>
          </span>
          <br/>
        </>}
        <br/><br/><hr/>

        <h3>Dati inseriti:</h3>
        <ul>
          {userDetails.type !== "privato" && values.noleggio_oppure_Acquisto !== "" &&
            <li>
              Noleggio o Acquisto: <strong>{values.noleggio_oppure_Acquisto}</strong>
            </li>
          }
          {/* {values.societaPrivato !== "" &&
            <li>
              Società o privato: <strong>{values.societaPrivato}</strong>
            </li>
          } */}
          {userDetails.type !== "privato" && values.noleggio_oppure_Acquisto === "noleggio" && values.numero_contratto !== "" &&
            <li>
              Numero contratto: <strong>{values.numero_contratto}</strong>
            </li>
          }
          {userDetails.type !== "privato" && values.noleggio_oppure_Acquisto === "noleggio" && values.societa_noleggio !== "" &&
            <li>
              Società di noleggio: <strong>{values.societa_noleggio}</strong>
            </li>
          }

          {userDetails.type !== 'distributore' && values.rivenditore_presente !== "" &&
            <li>
              È presente il rivenditore?: <strong>{values.rivenditore_presente === 'rivenditoreSi' ? 'Sì' : 'No'}</strong>
            </li>
          }
          {userDetails.type !== 'distributore' && values.rivenditore_presente === 'rivenditoreSi' && values.ragione_sociale_rivenditore !== "" &&
            <li>
              Ragione sociale rivenditore: <strong>{values.ragione_sociale_rivenditore}</strong>
            </li>
          }
          {userDetails.type !== 'distributore' && values.rivenditore_presente === 'rivenditoreSi' && values.piva_rivenditore !== "" &&
            <li>
              P.IVA rivenditore: <strong>{values.piva_rivenditore}</strong>
            </li>
          }
          {userDetails.type !== 'distributore' && values.rivenditore_presente === 'rivenditoreSi' && values.citta_rivenditore !== "" &&
            <li>
              Città rivenditore: <strong>{values.citta_rivenditore}</strong>
            </li>
          }

          {/* {userDetails.type === 'partita_iva' && values.tipo_identificativo !== "" &&
            <li>
              Tipo Identificativo: <strong>{values.tipo_identificativo}</strong>
            </li>
          }
          {userDetails.type === 'partita_iva' && values.tipo_identificativo === 'codice_univoco' && values.input_codice_univoco !== "" &&
            <li>
              Codice univoco: <strong>{values.input_codice_univoco}</strong>
            </li>
          }
          {userDetails.type === 'partita_iva' && values.tipo_identificativo === 'pec' && values.input_pec !== "" &&
            <li>
              PEC: <strong>{values.input_pec}</strong>
            </li>
          } */}
        </ul>
        <br/><hr/>
        {articles.length > 0 &&
          <>
            <h3>Articoli inseriti:</h3>
            <Grid container justify="flex-start" className={classes.root} spacing={2}>
              {articles.map((article, index) => (
                <Grid key={index} item xs={12}>
                  <ArticleDetail article={article} />
                </Grid>
              ))}
            </Grid>
          </>
        }
        <h3>Dettagli metodo di reso:</h3>
        {values.codice_articolo !== "" &&
          <>
            <span>
              Codice articolo: <strong>{values.codice_articolo}</strong>
            </span>
            <br/>
          </>
        }
        {values.nome_articolo !== "" &&
          <>
            <span>
              Nome articolo: <strong>{values.nome_articolo}</strong>
            </span>
            <br/>
          </>
        }
        {values.descrizione_articolo !== "" &&
          <>
            <span>
              Descrizione articolo: <strong>{values.descrizione_articolo}</strong>
            </span>
            <br/>
          </>
        }
        {values.matricola_o_lotto_articolo !== "" &&
          <>
            <span>
              Matricola o lotto articolo: <strong>{values.matricola_o_lotto_articolo}</strong>
            </span>
            <br/>
          </>
        }

        {values.descrizione_difetto !== "" &&
          <>
            <span>
              Descrizione difetto: <strong>{values.descrizione_difetto}</strong>
            </span>
            <br/>
          </>
        }
        {values.numero_pezzi !== "" &&
          <>
            <span>
              Numero pezzi: <strong>{values.numero_pezzi}</strong>
            </span>
            <br/>
          </>
        }
          {values.articolo_in_Garanzia !== "" &&
            <>
              <span>
                L'articolo è in garanzia? <strong>{values.articolo_in_Garanzia === 'garanziaSi' ? 'Sì' : 'No'}</strong>
              </span>
              <br/>
            </>
          }
        {values.uploadedGaranzia.length > 0 &&
          <>
            <span> Foto garanzia:<br />
              {values.uploadedGaranzia.map(file => {
                return(
                  <RenderThumb key={file.s3Id} file={file} />
                )
              })}
            </span>
            <br/>
          </>
        }
        {values.uploadedDifetto.length > 0 &&
          <>
            <span> Foto difetto:<br />
              {values.uploadedDifetto.map(file => {
                return(
                  <RenderThumb key={file.s3Id} file={file} />
                )
              })}
            </span>
            <br/>
          </>
        }

        {values.trasporto_a_cura_di !== "" &&
          <><span>
            Trasporto a cura di: <strong>{values.trasporto_a_cura_di}</strong>
          </span><br/></>
        }
        {values.trasporto_a_cura_di === 'Muster' && values.indirizzo_ritiro !== "" &&
          <><span>
            Indirizzo per ritiro: <strong>{values.indirizzo_ritiro}</strong>
          </span><br/></>
        }
        {values.trasporto_a_cura_di === 'Muster' && values.citta_ritiro !== "" &&
          <><span>
            Città per ritiro: <strong>{values.citta_ritiro}</strong>
          </span><br/></>
        }
        {values.trasporto_a_cura_di === 'Muster' && values.provincia_ritiro !== "" &&
          <><span>
            Provincia per ritiro: <strong>{values.provincia_ritiro}</strong>
          </span><br/></>
        }
        {values.trasporto_a_cura_di === 'Muster' && values.cap_ritiro !== "" &&
          <><span>
            Cap per ritiro: <strong>{values.cap_ritiro}</strong>
          </span><br/></>
        }
        {values.trasporto_a_cura_di === 'Muster' && values.telefono_ritiro !== "" &&
          <><span>
            N° Telefono per ritiro: <strong>{values.telefono_ritiro}</strong>
          </span><br/></>
        }
        <br/><br/>
        <div className={classes.printButtonContainer}>
          <Button className={classes.printButton}
            onClick={stampa}>
            Stampa o salva PDF riepilogo
          </Button>
        </div>
        <h3 style={{marginTop: '30px'}}>Ti preghiamo di prendere visione delle condizioni di reso non riparato.</h3>
        <Button variant="contained" color="primary" style={{marginBottom: '20px', marginBottom: '20px'}}
          href="https://muster-dikson.com/pdf/costi_reso_non_riparato.pdf" target="_blank"
        >
          Condizioni di reso
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.isChecked}
              onChange={() => _setIsChecked()}
              name="checkedB"
              color="primary"
            />
          }
          label="Se dall’analisi del reso non dovessero sussistere i requisiti di garanzia, prendo visione e accetto le condizioni di reso non riparato."
        />
        </div>
        {props.isChecked && <>
          <h3 style={{marginTop: '30px'}}>Se i dati sono corretti, procedi con l'invio.</h3>
          <p style={{marginBottom: '30px'}}>Se desideri cambiare qualcosa, torna indietro agli step precedenti</p>
        </>}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* <h2 style={{textAlign: "center"}} id="transition-modal-title">Pronto per l'invio?</h2>
            <p id="transition-modal-description">Una copia dei dati inseriti verrà inviata al seguente indirizzo mail:</p>
            <h3 style={{textAlign: "center"}}>
              <strong>{userDetails.email}</strong>
            </h3> */}
            <h2 style={{textAlign: "center"}} id="transition-modal-title">Hai quasi finito!</h2>
            <p id="transition-modal-description"> Controlla i dati, spunta la casella di accettazione delle condizioni di reso e premi "INVIA".</p>
            <Button color="default" className={classes.myButton} onClick={handleClose}>Chiudi questo avviso</Button>
          </div>
        </Fade>
      </Modal>
    </>
  </Fade>
  )
};

export { RiepilogoProblema as default }
