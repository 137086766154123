import React, { useReducer, useContext, useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Toolbar from '@material-ui/core/Toolbar';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import SettoriList from './settori/SettoriList';
import Start from './Start';
import TipoUtente from './TipoUtente';
import NumeroVerde from './NumeroVerde';
import HelpDesk from './HelpDesk';
import BeautyTrainer from './BeautyTrainer';
import SelezioneForm from './settori/SelezioneForm';
import SelezioneProblema from './settori/SelezioneProblema';
import Minuterie from './Minuterie';

import PrepareFormSegnalazione from "./form/PrepareFormSegnalazione";
import PrepareFormRMA from "./form/PrepareFormRMA";

import settoriReducer from '../reducers/settori';
import SettoriContext from '../context/settori-context';
import mailFormReducer from '../reducers/mailForm';
import MailFormContext from '../context/mailForm-context';
import navigationReducer from '../reducers/navigation';
import NavigationContext from '../context/navigation-context';
import PrepareFormMinuterie from './form/PrepareFormMinuterie';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#d5d5d5'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    position: 'relative'
  },
  modalExit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    width: '300px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  undoButton: {
    color: 'black',
    margin: '8px',
    padding: '20px 30px 20px 30px',
    border: '1px solid #b2b2b2',
    backgroundColor: '#ffffff'
  },
  cancelButton: {
    backgroundColor: '#13518b',
    color: 'white',
    margin: theme.spacing(1),
    padding: '20px 30px 20px 30px',
    '&:hover': {
      backgroundColor: '#276aaa'
    }
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topCorner: {
    backgroundColor: '#dc004e',
    position: 'absolute',
    right: "-25px",
    top: "-25px",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  developedBy: {
    marginBottom: '20px'
  }
}));


function MadeWithLove() {
  const classes = useStyles();

  return (
    <Typography className={classes.developedBy} variant="body2" color="textSecondary" align="center">
      {'Developed by '}
      <Link color="inherit" href="https://studiokey.it/" target="_blank">
        Studiokey
      </Link>
    </Typography>
  );
}

const Welcome = () => {
  function getScreenContent(comp) {
    switch (comp) {
      case "Start":
        return <Start checked={checked} navigate={handleNavigation}/>
      case "Home":
        return <TipoUtente checked={checked} close ={handleClose} />
      case 'settori':
        return <SettoriList checked={checked} close={handleClose}/>
      case 'parpro':
      return <NumeroVerde checked={checked} close={handleClose}/>
      case 'pararr':
      return <SelezioneForm checked={checked} mail={handleMail} temp={handleTemp} close={handleClose} closeDetails={campi} />
      case 'parele':
      return <SelezioneProblema checked={checked} mail={handleMail} temp={handleTemp} close={handleClose} closeDetails={campi} />
      case 'parmin':
        return <PrepareFormMinuterie checked={checked} close={handleClose} tipologia={handleTipologia} closeDetails={campi} mail={handleMail}/>
      case 'estpro':
      return <BeautyTrainer checked={checked} close={handleClose}/>
      case 'estnai':
      return <BeautyTrainer checked={checked} close={handleClose}/>
      case 'estarr':
      return <SelezioneForm checked={checked} mail={handleMail} close={handleClose} temp={handleTemp} closeDetails={campi}/>
      case 'estatt':
      return <SelezioneForm checked={checked} mail={handleMail} close={handleClose} temp={handleTemp} closeDetails={campi}/>
      case 'estapp':
      return <SelezioneProblema checked={checked} mail={handleMail} close={handleClose} temp={handleTemp} closeDetails={campi}/>
      case 'estele':
      return <SelezioneProblema checked={checked} mail={handleMail} close={handleClose} temp={handleTemp} closeDetails={campi}/>
      case 'estmin':
        return <Minuterie checked={checked} close={handleClose}/>
      case 'segpro':
        return <PrepareFormSegnalazione checked={checked} tipologia={handleTipologia} close={handleClose} closeDetails={campi}/>
      case 'resart':
        return <PrepareFormRMA checked={checked} tipologia={handleTipologia} close={handleClose} closeDetails={campi}/>
      case 'helpde':
        return <HelpDesk checked={checked} close={handleClose} closeDetails={campi}/>
      default:
        throw new Error('Unknown component to load');
    }
  }

  const classes = useStyles();

  //used for component animation
  const [checked, setChecked] = React.useState(true);
  function handleChange(){
    setChecked(prev => !prev);
  }

  const datiIniziali = useContext(SettoriContext);
  const [dati, aggiornaDati] = useReducer(settoriReducer, datiIniziali);

  const mailFormIniziale = useContext(MailFormContext);
  const [mail, aggiornaMail] = useReducer(mailFormReducer, mailFormIniziale);

  const statusIniziale = useContext(NavigationContext);
  const [campi, aggiornaCampi] = useReducer(navigationReducer, statusIniziale);
  const [exitModalOpen, setExitModalOpen] = useState(false);

  // useEffect(()=> {
  //   console.log(campi)
  // }, [campi])
  
  const handleNavigation = (
    corrente = "Home", correnteId = "Home", close = null, closeId = null
  ) => {
    aggiornaCampi({
      type: 'NAVIGA_SETTORE',
      payload: {
        current: corrente,
        currentId: correnteId,
        close: close,
        closeId: closeId
      }
    });
  };

  const _handleClose = (close, closeId) => {
    setExitModalOpen(true);
    // handleClose(close, closeId)
  }

  const _confirmClose = () => {
    handleNavigation(campi.close, campi.closeId, campi.close, campi.closeId)
    setExitModalOpen(false);
  }
  
  const handleClose = (close, closeId) => {
    aggiornaCampi({
      type: 'CHIUDI_MODULO',
      payload: {
        close: close,
        closeId: closeId
      }
    });
  };

  const handleTemp = (temp, tempId) => {
    aggiornaCampi({
      type: 'TEMP_NAV',
      payload: {
        temp: temp,
        tempId: tempId
      }
    })
  }

  const handleMail = (mail) => {
    aggiornaMail({
      type: 'AGGIORNA_MAIL',
      payload: {
        mail: mail
      }
    })
  }
  
  const handleTipologia = (tipologia) => {
    aggiornaMail({
      type: 'AGGIORNA_TIPOLOGIA',
      payload: {
        tipologia: tipologia
      }
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
            <Button size="large"
              onClick={ () => handleNavigation()}>
              Müster & Dikson - Assistenza
            </Button>
        </Toolbar>
      </AppBar>
      <NavigationContext.Provider value={{campi, aggiornaCampi, handleNavigation}}>
      <SettoriContext.Provider value={{dati, aggiornaDati}}>
      <MailFormContext.Provider value={{mail, aggiornaMail}}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {campi.current !== 'Start' && (
              <Typography component="h1" variant="h4" align="center">
                {campi.current}
              </Typography>
            )}
            {campi.close !== null && (
              <Button color="secondary" variant="contained"
                className={classes.topCorner}
                onClick={() => {
                  campi.currentId === 'segpro' || campi.currentId === 'resart' ? _handleClose() :
                  handleNavigation(campi.close, campi.closeId, campi.close, campi.closeId)
                }}>
                <CloseIcon></CloseIcon>
              </Button>
            )}
            <React.Fragment>
              {getScreenContent(campi.currentId)}
            </React.Fragment>
          </Paper>
          <MadeWithLove/>
        </main>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalExit}
          open={exitModalOpen}
          onClose={() => setExitModalOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={exitModalOpen}><>
            <div className={classes.modalPaper}>
              <h3>Vuoi davvero uscire dalla compilazione della richiesta?</h3>
              <div className={classes.buttonContainer}>
                <Button color="default" variant="outlined" className={classes.undoButton} onClick={() => setExitModalOpen(false)}>Annulla</Button>
                <Button color="default" className={classes.cancelButton} onClick={() => _confirmClose()}>Esci</Button>
              </div>
            </div>
          </></Fade>
        </Modal>
      </MailFormContext.Provider>
      </SettoriContext.Provider>
      </NavigationContext.Provider>
    </React.Fragment>
  );
}

export { Welcome as default }
