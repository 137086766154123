const mailFormReducer = (state, action) => {
  switch (action.type) {
    case 'AGGIORNA_MAIL':
      return {
          ...state,
          mail: action.payload.mail
      }
    case 'AGGIORNA_TIPOLOGIA':
      return {
          ...state,
          tipologia: action.payload.tipologia
      }
    default:
      return state
  }
}
export { mailFormReducer as default };
