import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import AssistenzaNew from './components/AssistenzaNew';
import './index.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <AssistenzaNew/>
  </Provider>, 
  rootElement
);
